import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { styles as commonStyles } from '../PermitUpsert/PermitUpsert.styles';

export const styles = (theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    flexWrap: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    inputControlEditMode: {
      paddingTop: theme.spacing(2.5),
    },
    containerClass: {
      paddingTop: theme.spacing(3.5),
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  ...commonStyles(theme),
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputControlEditMode: {
    paddingTop: theme.spacing(2.5),
  },
  containerClass: {
    paddingTop: theme.spacing(3.5),
  },
}));
