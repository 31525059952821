import { AlertStore } from '@uvgo-shared/alert';
import {
  AccessLevelModel,
  IClasses,
  SettingsTypeModel,
  SourceTypeModel,
  UIStore,
  Utilities,
} from '@wings-shared/core';
import { Logger } from '@wings-shared/security';
import { FARTypeModel, SettingsType, SettingTypeBase } from '@wings/shared';
import { IBaseEditorProps } from '@wings-shared/custom-ag-grid';
import { ColDef, ValueFormatterParams, RowNode } from 'ag-grid-community';
import { AxiosError } from 'axios';
import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { PermitSettingsStore } from '../../../Shared';
import PurposeOfFlightEditor from './PurposeOfFlightsEditor';

interface Props extends Partial<IBaseEditorProps> {
  permitSettingsStore?: PermitSettingsStore;
  classes?: IClasses;
}

@inject('permitSettingsStore')
@observer
class FARType extends SettingTypeBase<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadFARTypes();
  }

  private get settingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  /* istanbul ignore next */
  private loadSettingsData() {
    UIStore.setPageLoader(true);
    forkJoin([
      this.settingsStore.getSourceTypes(),
      this.settingsStore.getAccessLevels(),
      this.settingsStore.getFlightOperationalCategories(),
      this.settingsStore.getFlightPurposes(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        error: (error: AxiosError) => Logger.error(error.message),
      });
  }

  /* istanbul ignore next */
  private loadFARTypes(): void {
    this.settingsStore
      .getFARTypes()
      .pipe(tap((response: FARTypeModel[]) => this.settingsTypesRef.current?.setData(response)))
      .subscribe({
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  }

  /* istanbul ignore next */
  private upsertFARType(rowIndex: number, model: FARTypeModel): void {
    UIStore.setPageLoader(true);
    this.settingsStore
      .upsertFARType(model)
      .pipe(
        takeUntil(this.destroy$),
        tap((response: FARTypeModel) => this.settingsTypesRef.current?.updateTableItem(rowIndex, response)),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellEditorParams: {
        ignoreNumber: true,
        rules: 'required|string|between:1,100',
        isUnique: (value: string) => {
          return !this.settingsStore.farTypes.some(({ name }) => Utilities.isEqual(name, value?.trim()));
        },
      },
    },
    {
      headerName: 'CAPPS Code',
      field: 'cappsCode',
      maxWidth: 130,
      cellEditorParams: {
        ignoreNumber: true,
        rules: 'required|string|between:1,4',
        getDisableState: (node: RowNode) => Boolean(node.data?.id),
        isUnique: (value: string) => {
          return !this.settingsStore.farTypes.some(({ cappsCode }) => Utilities.isEqual(cappsCode, value?.trim()));
        },
      },
    },
    {
      headerName: 'Flight Operational Category',
      field: 'flightOperationalCategory',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Flight Operational Category',
        getAutoCompleteOptions: () => this.settingsStore.flightOperationalCategories,
      },
    },
    {
      headerName: 'Purpose Of Flight',
      field: 'purposeOfFlights',
      filter: false,
      cellRenderer: 'purposeOfFlightEditor',
      cellEditor: 'purposeOfFlightEditor',
      minWidth: 150,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRowEditing: true,
        getAutoCompleteOptions: () => this.settingsStore.flightPurposes,
      },
    },
    {
      headerName: 'Access Level',
      field: 'accessLevel',
      cellEditor: 'customAutoComplete',
      maxWidth: 130,
      comparator: (current: AccessLevelModel, next: AccessLevelModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Access Level',
        getAutoCompleteOptions: () => this.settingsStore.accessLevels,
      },
    },
    {
      headerName: 'Source Type',
      field: 'sourceType',
      cellEditor: 'customAutoComplete',
      maxWidth: 130,
      comparator: (current: SourceTypeModel, next: SourceTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Source Type',
        getAutoCompleteOptions: () => this.settingsStore.sourceTypes,
      },
    },
  ];

  public render(): ReactNode {
    return (
      <SettingsType
        ref={this.settingsTypesRef}
        rowData={this.settingsStore.farTypes}
        onGetNewModel={() => new FARTypeModel({ id: 0 })}
        onUpsert={(rowIndex: number, data: FARTypeModel) => this.upsertFARType(rowIndex, data)}
        type="FAR Type"
        columnDefs={this.columnDefs}
        frameworkComponents={{ purposeOfFlightEditor: PurposeOfFlightEditor }}
        onEditingStarted={() => this.loadSettingsData()}
      />
    );
  }
}

export default FARType;
