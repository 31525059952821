import React, { ReactNode } from 'react';
import { EntityMapModel, UIStore, withRouter } from '@wings-shared/core';
import PermitUpsert, { BaseProps } from '../PermitUpsert/PermitUpsert';
import {
  Collapsable,
  ConfirmDialog,
  ConfirmNavigate,
  DetailsEditorWrapper,
  TitleContentWrapper,
} from '@wings-shared/layout';
import { VIEW_MODE } from '@wings/shared';
import { finalize, takeUntil } from 'rxjs/operators';
import PermitEditorActions from '../PermitEditorActions/PermitEditorActions';
import { fields } from './Fields';
import { inject, observer } from 'mobx-react';
import PermitDocument from './PermitDocument';
import { forkJoin } from 'rxjs';
import { action, observable } from 'mobx';
import { PermitDocumentModel, PermitModel } from '../../../Shared';
import { EDITOR_TYPES, ViewInputControl } from '@wings-shared/form-controls';
import { ModalStore } from '@uvgo-shared/modal-keeper';

type Props = BaseProps;
@inject('permitStore', 'permitSettingsStore')
@observer
class PermitRequirements extends PermitUpsert<Props> {
  @observable private isRowEditing: boolean = false;

  constructor(p: Props) {
    super(p, fields);
  }

  componentDidMount(): void {
    this.loadInitialData();
  }

  @action
  private loadInitialData() {
    forkJoin([
      this.props.permitSettingsStore?.getDocuments(),
      this.props.permitSettingsStore?.getFARTypes(),
      this.props.permitSettingsStore?.getElements(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
    this.setFormValues(this.props.permitStore?.permitDataModel as PermitModel);
  }

  private upsertData() {
    const { permitRequiredElements } = this.form.values();
    this.permitModel = new PermitModel({
      ...this.permitModel,
      permitRequiredElements: permitRequiredElements.map(({ id, ...rest }) => {
        return new EntityMapModel({ id: Math.floor(id), ...rest });
      }),
    });
    this.onUpsertAction();
  }

  @action
  private updateDocuments(permitDocuments: PermitDocumentModel[]): void {
    this.permitModel = new PermitModel({
      ...this.permitModel,
      permitDocuments: permitDocuments.map(({ id, ...rest }) => {
        return new PermitDocumentModel({ id: Math.floor(id), ...rest });
      }),
    });
  }

  @action
  private updateRowEditing(isEditing: boolean): void {
    this.isRowEditing = isEditing;
  }

  private get hasGeneralError(): boolean {
    return this.hasError || this.isRowEditing;
  }

  @action
  private setInitialData(): void {
    this.viewMode = VIEW_MODE.DETAILS;
    this.updateRowEditing(false);
    this.form.reset();
    this.setFormValues(this.permitStore.permitDataModel);
    this.permitModel = new PermitModel({
      ...this.permitStore.permitDataModel,
    });
    return;
  }

  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      return ModalStore.open(
        <ConfirmDialog
          title="Confirm Cancellation"
          message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
          yesButton="Yes"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            ModalStore.close();
            this.setInitialData();
          }}
        />
      );
    }
    this.setInitialData();
    this.navigateToPermits();
    return;
  }

  private get headerActions(): ReactNode {
    return (
      <PermitEditorActions
        hasError={this.hasGeneralError || UIStore.pageLoading || this.isRowEditing}
        isDetailsView={this.isDetailsView}
        onCancelClick={() => this.onCancel()}
        onUpsert={() => this.upsertData()}
        onSetViewMode={(mode: VIEW_MODE) => this.setViewMode(mode)}
        isRowEditing={this.isRowEditing}
      />
    );
  }

  private get elements(): EntityMapModel[] {
    return this.props.permitSettingsStore?.elements.map(
      x => new EntityMapModel({ name: x.name, entityId: x.id })
    ) as EntityMapModel[];
  }

  render() {
    return (
      <ConfirmNavigate isBlocker={this.isDataChanged}>
        <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={!this.isDetailView}>
          <TitleContentWrapper permitTitle={this.permitModel.permitTitle} title="Requirements">
            <Collapsable title="Elements">
              <ViewInputControl
                isEditable={this.isEditable || !this.isDetailView}
                type={EDITOR_TYPES.DROPDOWN}
                multiple={true}
                options={this.elements}
                field={this.getField('permitRequiredElements')}
                onValueChange={(option, fieldKey) => this.onValueChange(option, fieldKey)}
              />
            </Collapsable>
            <PermitDocument
              isEditable={this.isEditable || !this.isDetailView || this.isRowEditing}
              permitDocuments={this.permitModel.permitDocuments}
              onDataSave={permitDocument => this.updateDocuments(permitDocument)}
              onRowEditing={isEditing => this.updateRowEditing(isEditing)}
            />
          </TitleContentWrapper>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}

export default withRouter(PermitRequirements);
export { PermitRequirements as PurePermitRequirements };
