import { BaseAirportStore, VIEW_MODE } from '@wings/shared';
import React, { ReactNode } from 'react';
import {
  PermitDocumentFarTypeModel,
  PermitDocumentModel,
  PermitModuleSecurity,
  PermitSettingsStore,
  PermitStore,
} from '../../../Shared';
import {
  ColDef,
  GridOptions,
  ValueFormatterParams,
  ICellRendererParams,
  ICellEditorParams,
  RowEditingStartedEvent,
  ICellEditor,
  RowNode,
} from 'ag-grid-community';
import { action, observable } from 'mobx';
import {
  IdNameCodeModel,
  Utilities,
  getStringToYesNoNull,
  getYesNoNullToBoolean,
  DATE_FORMAT,
  ENTITY_STATE,
  SelectOption,
  withRouter,
  GRID_ACTIONS,
  IOptionValue,
  cellStyle,
  EntityMapModel,
  SettingsTypeModel,
  UIStore,
  regex,
} from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import { CollapsibleWithButton, ChildGridWrapper, ConfirmDialog } from '@wings-shared/layout';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  AgGridCellEditor,
  AgGridActions,
  AgGridAutoComplete,
  AgGridCheckBox,
  AgGridChipView,
  AgGridGroupHeader,
  AgGridSelectControl,
  BaseGrid,
  CustomAgGridReact,
} from '@wings-shared/custom-ag-grid';
import PermitDocumentValueEditor, { IChildRef } from './PermitDocumentValueEditor';
import PermitConditionValueRenderer from './PermitConditionValueRenderer';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { debounce } from '@material-ui/core';

interface Props extends ICellRendererParams {
  permitSettingsStore?: PermitSettingsStore;
  permitStore?: PermitStore;
  onDataSave: (response: PermitDocumentModel[]) => void;
  permitDocuments: PermitDocumentModel[];
  isEditable?: boolean;
  onRowEditing: (isEditing: boolean) => void;
  params?: { permitId: number; viewMode: VIEW_MODE };
}

@inject('permitStore', 'permitSettingsStore')
@observer
class PermitDocument extends BaseGrid<Props, PermitDocumentModel> {
  @observable private optionalColumns: string[] = [ 'permitDocumentFarType' ];
  @observable private disabledColumns: string[] = [];
  @observable private airport: EntityMapModel[] = [];
  @observable private hasRuleConditionalOperator: boolean = false;
  @observable private hasRuleField: boolean = false;
  @observable private hasRuleEntityType: boolean = false;
  private readonly alertMessageId: string = 'PermitDocumentId';
  private baseAirportStore = new BaseAirportStore();

  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    this.loadInitialData();
    this.isRowEditingStarted$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.hasConditionValueError();
      this.hasError = true;
      this.setConditionRules();
    });
  }

  /* istanbul ignore next */
  componentDidUpdate(prevProps: Props) {
    if (this.props.isEditable !== prevProps.isEditable) {
      this.loadInitialData();
    }
  }

  private loadInitialData() {
    this.data = this.props.permitDocuments;
  }

  /* istanbul ignore next */
  private get permitSettingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  /* istanbul ignore next */
  private get permitStore(): PermitStore {
    return this.props.permitStore as PermitStore;
  }

  @action
  private setEditorFlags(): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi) || this.hasConditionValue;
    this.commonErrorMessage = this.hasConditionValue
      ? 'Value is Required'
      : Utilities.getErrorMessages(this.gridApi).toString();
  }

  private updateTableData(): void {
    this.data = this._getAllTableRows().map(
      doc =>
        new PermitDocumentModel({
          ...doc,
          id: doc.id || Utilities.getTempId(true),
          entityState: ENTITY_STATE.NEW,
        })
    );
    this.props.onDataSave(this.data);
  }

  /* istanbul ignore next */
  private isAlreadyExists(id: number, document: any): boolean {
    const isDuplicateData = this.data.some(a => Utilities.isEqual(a.document.id, document?.id) && id !== a.id);

    if (isDuplicateData) {
      this.showAlert(`Permit Document already exists with Code:${document.code}`, this.alertMessageId);
      return true;
    }
    return false;
  }

  /* istanbul ignore next */
  private upsertDocument(rowIndex: number) {
    const editorInstance: ICellEditor[] = this.gridApi.getCellEditorInstances({
      columns: [ 'document', 'isApplicableToAllFarTypes', 'permitDocumentFarType' ],
    });
    const doc = editorInstance[0].getValue();
    const isApplicableToAll = editorInstance[1].getValue();
    const farTypes = editorInstance[2].getValue();

    const data: PermitDocumentModel = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(data.id, doc)) {
      return;
    }
    if (isApplicableToAll === false && !farTypes.length) {
      this.showAlert('FAR Type is required', 'farTypes');
      return;
    }

    if (doc?.id) {
      this.gridApi.stopEditing();
      this.updateTableData();
      this.props.onRowEditing(false);
      return;
    }
    this.props.onRowEditing(true);
    this.showAlert('Document is required', 'permitDocument');
  }

  private viewRenderer(chips: PermitDocumentFarTypeModel[], getTagProps?: AutocompleteGetTagProps): ReactNode {
    const numTags = chips.length;
    const limitTags = 1;
    const chipsList = [ ...chips ].slice(0, limitTags);

    return (
      <div>
        {chipsList.map((type: PermitDocumentFarTypeModel, index) => {
          return (
            <Chip
              key={type.id}
              label={type.code || type.label}
              {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
            />
          );
        })}
        {numTags > limitTags && ` +${numTags - limitTags} more`}
      </div>
    );
  }

  private viewRendererAirport(chips: EntityMapModel[], getTagProps?: AutocompleteGetTagProps): ReactNode {
    const numTags = chips?.length || 0;
    const limitTags = 1;
    const chipsList = [ ...chips ].slice(0, limitTags);
    return (
      <div>
        {chipsList.map((data: EntityMapModel, index) => {
          return (
            <Chip
              key={data.id}
              label={data?.code}
              {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
            />
          );
        })}
        {numTags > limitTags && ` +${numTags - limitTags} more`}
      </div>
    );
  }

  private viewRendererPermitClassification(chips: EntityMapModel[], getTagProps?: AutocompleteGetTagProps): ReactNode {
    const numTags = chips?.length || 0;
    const limitTags = 1;
    const chipsList = [ ...chips ].slice(0, limitTags);
    return (
      <div>
        {chipsList.map((data: EntityMapModel, index) => {
          return (
            <Chip
              key={data.id}
              label={data?.label}
              {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
            />
          );
        })}
        {numTags > limitTags && ` +${numTags - limitTags} more`}
      </div>
    );
  }

  private get farTypeOptions(): IdNameCodeModel[] {
    const options = this.permitSettingsStore.farTypes.map(
      x =>
        new IdNameCodeModel({
          id: x.id,
          name: x.name,
          code: x.cappsCode,
        })
    );

    return options;
  }

  private get classificationOptions(): EntityMapModel[] {
    const options = this.permitSettingsStore.permitClassifications.map(
      x =>
        new EntityMapModel({
          name: x.name,
          entityId: x.id,
        })
    );

    return options;
  }

  private get ruleFieldOption() {
    const options = this.permitSettingsStore.ruleEntityParameterConfigs
      .filter(x => x.ruleEntityType?.name === this.getInstanceValue('ruleEntityType')?.label)
      .map(x => new SettingsTypeModel({ id: x.id, name: x.entityParameter }));

    return options;
  }

  private get conditionalOpertorOptions() {
    const options = this.permitSettingsStore.ruleEntityParameterConfigs
      .filter(x => x.entityParameter === this.getInstanceValue('ruleField')?.label)
      .map(x => x.supportedOperators)
      .flat();

    return options;
  }

  /* istanbul ignore next */
  private searchAirports(propertyValue = ''): void {
    this.baseAirportStore
      .searchWingsAirports(propertyValue)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: airports =>
          (this.airport = airports.map(
            x =>
              new EntityMapModel({
                name: x.name,
                entityId: x.id,
                code: x.displayCode,
              })
          )),
      });
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Document',
      field: 'document',
      headerTooltip: 'Document',
      cellEditor: 'customAutoComplete',
      minWidth: 180,
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Document',
        getAutoCompleteOptions: () => this.permitSettingsStore.documents,
        valueGetter: (option: IdNameCodeModel) => option.value,
        formatValue: value => value,
      },
    },
    {
      headerName: 'Is Applicable to All Far Types',
      field: 'isApplicableToAllFarTypes',
      headerTooltip: 'Is Applicable to All Far Types',
      cellEditor: 'customSelect',
      minWidth: 150,
      valueFormatter: ({ value }) => getStringToYesNoNull(value?.label || value),
      cellEditorParams: {
        placeHolder: '',
        isBoolean: true,
        formatValue: value => getYesNoNullToBoolean(value?.label ?? value),
      },
    },
    {
      headerName: 'FAR Type',
      field: 'permitDocumentFarType',
      headerTooltip: 'FAR Type',
      cellEditor: 'customAutoComplete',
      filter: false,
      minWidth: 180,
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        chipLabelField: 'code',
      },
      cellEditorParams: {
        multiSelect: true,
        disableCloseOnSelect: true,
        placeHolder: 'FAR Type',
        getAutoCompleteOptions: () => this.farTypeOptions,
        renderTags: (values: PermitDocumentFarTypeModel[], getTagProps: AutocompleteGetTagProps) =>
          this.viewRenderer(values, getTagProps),
        getDisableState: () => this.disabledColumns.includes('permitDocumentFarType'),
      },
    },
    {
      headerName: 'Oracle Modified Date',
      headerTooltip: 'Oracle Modified Date',
      field: 'oracleModifiedDate',
      minWidth: 150,
      editable: false,
      sortable: true,
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
    },
    {
      headerName: 'Permit Classification',
      field: 'appliedPermitClassifications',
      headerTooltip: 'Permit Classification',
      cellEditor: 'customAutoComplete',
      filter: false,
      minWidth: 180,
      cellRenderer: 'agGridChipView',
      cellEditorParams: {
        multiSelect: true,
        disableCloseOnSelect: true,
        placeHolder: 'Permit Classification',
        getAutoCompleteOptions: () => this.classificationOptions,
        renderTags: (values: EntityMapModel[], getTagProps: AutocompleteGetTagProps) =>
          this.viewRendererPermitClassification(values, getTagProps),
      },
    },
    {
      headerName: 'Airport',
      field: 'appliedPermitDocumentAirports',
      cellEditor: 'customAutoComplete',
      headerTooltip: 'Airport',
      filter: false,
      cellRenderer: 'agGridChipView',
      minWidth: 250,
      cellRendererParams: {
        chipLabelField: 'code',
      },
      cellEditorParams: {
        multiSelect: true,
        disableCloseOnSelect: true,
        placeHolder: 'Airport',
        onSearch: value => this.searchAirports(value),
        getAutoCompleteOptions: () => {
          return this.airport;
        },
        useControlledValue: true,
        renderTags: (values: EntityMapModel[], getTagProps: AutocompleteGetTagProps) =>
          this.viewRendererAirport(values, getTagProps),
      },
    },
    {
      headerName: 'Entity Type',
      field: 'ruleEntityType',
      headerTooltip: 'Entity Type',
      cellEditor: 'customAutoComplete',
      minWidth: 140,
      valueFormatter: ({ value }) => value?.label || '',
      cellEditorParams: {
        placeHolder: 'Entity Type',
        getAutoCompleteOptions: () => this.permitSettingsStore.ruleEntities,
        valueGetter: (option: SettingsTypeModel) => option.value,
        formatValue: value => value,
      },
    },
    {
      headerName: 'Field',
      field: 'ruleField',
      headerTooltip: 'Field',
      cellEditor: 'customAutoComplete',
      minWidth: 180,
      valueFormatter: ({ value }) => value?.label || '',
      cellEditorParams: {
        placeHolder: 'Field',
        getAutoCompleteOptions: () => this.ruleFieldOption,
        valueGetter: (option: SettingsTypeModel) => option.value,
        formatValue: value => value,
        isRequired: () => this.getInstanceValue('ruleEntityType')?.label,
        getDisableState: (node: RowNode) => !this.hasRuleEntityType,
      },
    },
    {
      headerName: 'Conditional Operator',
      field: 'ruleConditionalOperator',
      cellEditor: 'customAutoComplete',
      headerTooltip: 'Conditional Operator',
      minWidth: 190,
      valueFormatter: ({ value }) => {
        return value?.label || '';
      },
      cellEditorParams: {
        placeHolder: 'Conditional Operator',
        getAutoCompleteOptions: () => this.conditionalOpertorOptions,
        isRequired: () => this.getInstanceValue('ruleField')?.label && this.getInstanceValue('ruleEntityType')?.label,
        getDisableState: (node: RowNode) => !this.hasRuleField,
      },
    },
    {
      headerName: 'Value',
      headerTooltip: 'Value',
      field: 'ruleValues',
      minWidth: 150,
      cellRenderer: 'conditionValueRenderer',
      cellEditor: 'conditionValueEditor',
      cellEditorParams: {
        settingsStore: this.permitSettingsStore,
        permitStore: this.permitStore,
        isLoading: () => UIStore.pageLoading,
        getDisableState: (node: RowNode) => !this.hasRuleConditionalOperator,
        isRequired: (node: RowNode) => this.hasRuleConditionalOperator && this.hasRuleField && this.hasRuleEntityType,
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      suppressSizeToFit: true,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !this.props.isEditable,
            action: GRID_ACTIONS.EDIT,
          },
          {
            title: 'Delete',
            isHidden: !this.props.isEditable,
            action: GRID_ACTIONS.DELETE,
          },
        ],
      },
    },
  ];

  @action
  private setConditionRules(): void {
    this.hasRuleConditionalOperator = Boolean(
      this.getInstanceValue<SettingsTypeModel>('ruleConditionalOperator')?.value
    );
    this.hasRuleField = Boolean(this.getInstanceValue<SettingsTypeModel>('ruleField')?.label);
    this.hasRuleEntityType = Boolean(this.getInstanceValue<SettingsTypeModel>('ruleEntityType')?.value);
  }

  /* istanbul ignore next */
  @action
  public onInputChange({ colDef }: ICellEditorParams, value: string): void {
    this.hasConditionValueEdittor(colDef.field, value);
    this.setEditorFlags();
  }

  private get hasConditionValue(): boolean {
    const instance = this.getComponentInstance<IChildRef>('ruleValues');
    const conditionValue = instance.getValue() ? instance.getValue()[0]?.label?.toString() : '';
    return this.hasRuleConditionalOperator && !Boolean(conditionValue);
  }

  @action
  private hasConditionValueError(): void {
    const conditionValues = this.getComponentInstance<IChildRef>('ruleValues');
    conditionValues?.setConditionOperator(this.getInstanceValue('ruleConditionalOperator')?.label);
    this.getComponentInstance('ruleValues')?.setEditorType(this.getInstanceValue('ruleField')?.label);
    conditionValues?.setRules(this.hasRuleConditionalOperator ? 'required' : '');
    this.setEditorFlags();
  }

  @action
  private hasConditionValueEdittor(field: any, value: any): void {
    const conditionValues = this.getComponentInstance<IChildRef>('ruleValues');
    const checkEntityField =
      !this.getInstanceValue('ruleField')?.label && !this.getInstanceValue('ruleConditionalOperator')?.label;
    switch (field) {
      case 'isApplicableToAllFarTypes':
        if (value) {
          this.getComponentInstance('permitDocumentFarType').setValue([]);
          this.disabledColumns = this.optionalColumns;
          return;
        }
        this.disabledColumns = [];
        break;
      case 'ruleEntityType':
        this.getComponentInstance('ruleField').setValue(null);
        this.getComponentInstance('ruleConditionalOperator').setValue(null);
        this.getComponentInstance('ruleValues')?.setValue([]);
        this.setConditionRules();
        break;
      case 'ruleField':
        this.getComponentInstance('ruleConditionalOperator').setValue(null);
        this.getComponentInstance('ruleValues')?.setValue([]);
        this.setConditionRules();
        break;
      case 'ruleConditionalOperator':
        conditionValues?.setConditionOperator(this.getInstanceValue('ruleConditionalOperator')?.label);
        this.getComponentInstance('ruleValues')?.setEditorType(this.getInstanceValue('ruleField')?.label);
        this.getComponentInstance('ruleValues')?.setValue([]);
        this.setConditionRules();
        break;
      case 'ruleValues':
        if (!checkEntityField) {
          const ruleFieldValue = this.getInstanceValue('ruleField')?.label;
          switch (ruleFieldValue) {
            case 'FIRAirways':
              const isValidFirAirways = regex.firAirwaysFormat.test(value[0]?.ruleValue?.toString().trim());
              conditionValues?.setRules(isValidFirAirways);
              conditionValues?.setCustomError(!isValidFirAirways ? 'Value format should be like ZJSA(A1)' : '');
              this.hasError = !isValidFirAirways || checkEntityField;
              break;
            case 'MinFlightLevelInCountry':
            case 'MaxFlightLevelInCountry':
              const isValidMaxMin = regex.minMaxFlightLevelInCountry.test(value[0]?.ruleValue?.toString().trim());
              conditionValues?.setRules(isValidMaxMin);
              conditionValues?.setCustomError(!isValidMaxMin ? 'The field must be between -999999 and 99999.' : '');
              this.hasError = !isValidMaxMin || checkEntityField;
              break;
          }
        }
        break;
      default:
        break;
    }
  }

  /* istanbul ignore next */
  @action
  public onDropDownChange(params: ICellEditorParams, value: any): void {
    this.hasConditionValueEdittor(params.colDef.field, value);
    // Need Some delay to prepare editor components inside Grid
    debounce(() => this.hasConditionValueError(), 200)();
  }

  /* istanbul ignore next */
  private deleteDocument(model: PermitDocumentModel): void {
    ModalStore.close();
    this._removeTableItems([ model ]);
    this.gridApi.stopEditing();
    this.updateTableData();
  }

  private confirmRemoveDocument(rowIndex: number): void {
    const model: PermitDocumentModel = this._getTableItem(rowIndex);
    if (model.id === 0) {
      this.deleteDocument(model);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this Document?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.deleteDocument(model)}
      />
    );
  }

  @action
  private setDisableColumns(isApplicableToAll: IOptionValue) {
    if (
      Utilities.isEqual((isApplicableToAll as SelectOption)?.label, 'Yes') ||
      Utilities.isEqual(isApplicableToAll as boolean, true)
    ) {
      this.disabledColumns = [ 'permitDocumentFarType' ];
      return;
    }
    this.disabledColumns = [];
    return;
  }

  @action
  private cancelEditing(rowIndex): void {
    this._cancelEditing(rowIndex);

    const tableData = this._getAllTableRows();
    if (!tableData.length) {
      this.props?.onRowEditing(false);
      return;
    }

    const data: PermitDocumentModel = this._getTableItem(rowIndex);
    if (data?.id) {
      this.setDisableColumns(data?.isApplicableToAllFarTypes);
      this.props?.onRowEditing(false);
    }
  }

  @action
  private startEditing(index: number, field: string) {
    const data = this._getTableItem(index);
    const { isApplicableToAllFarTypes } = data;
    this.setDisableColumns(isApplicableToAllFarTypes);
    this._startEditingCell(index, field);
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this.startEditing(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertDocument(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
        this.cancelEditing(rowIndex);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveDocument(rowIndex);
        break;
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: true,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => this.hasError,
        getVisibleState: () => this.props.isEditable,
        getEditableState: () => this.props.isEditable,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });

    return {
      ...baseOptions,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customHeader: AgGridGroupHeader,
        checkBoxRenderer: AgGridCheckBox,
        agGridChipView: AgGridChipView,
        customAutoComplete: AgGridAutoComplete,
        customSelect: AgGridSelectControl,
        conditionValueEditor: PermitDocumentValueEditor,
        conditionValueRenderer: PermitConditionValueRenderer,
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        this._onRowEditingStarted(event);
        this.props.onRowEditing(true);
        this.permitSettingsStore.getPermitClassifications().subscribe();
        this.permitSettingsStore.getRuleEntities().subscribe();
        this.permitSettingsStore.getRuleEntityParameterConfigs().subscribe();
      },
      suppressClickEdit: true,
    };
  }

  private addPermitDocument(): void {
    const permitId = this.props.params?.permitId;
    this.disabledColumns = [];
    const model: PermitDocumentModel = new PermitDocumentModel({ id: 0, permitId: Number(permitId) });
    this._addNewItems([ model ], { startEditing: true, colKey: 'document' });
    this.hasError = true;
  }

  render() {
    return (
      <CollapsibleWithButton
        title="Documents"
        buttonText="Add Document"
        isButtonDisabled={this.isProcessing || !(PermitModuleSecurity.isEditable && this.props.isEditable)}
        onButtonClick={() => this.addPermitDocument()}
        onExpandButtonClick={() => this.autoSizeColumns()}
      >
        <ChildGridWrapper hasAddPermission={false}>
          <CustomAgGridReact
            isRowEditing={this.isRowEditing}
            rowData={this.data}
            gridOptions={this.gridOptions}
            disablePagination={this.isRowEditing}
          />
        </ChildGridWrapper>
      </CollapsibleWithButton>
    );
  }
}

export default withRouter(PermitDocument);
export { PermitDocument as PurePermitDocument };
