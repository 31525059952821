import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { withStyles, Chip } from '@material-ui/core';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { AirportModel, CountryModel, StateModel, FARTypeModel, FIRModel } from '@wings/shared';
import { styles } from './PermitExceptionRuleViewControl.styles';
import { SecondaryButton } from '@uvgo-shared/buttons';
import {
  PermitExceptionRuleModel,
  PermitStore,
  PermitSettingsStore,
  PERMIT_RULE_SOURCES,
  RuleFilterModel,
  BooleanOperators,
  AerodromeReferenceCodeModel,
  RuleEntityParameterConfigModel,
  RuleValueModel,
} from '../../../Shared';
import { BasePermitExceptionRuleViewControl } from './BasePermitExceptionRuleViewControl';
import { EDITOR_TYPES, ViewInputControl, ChipInputControl, IViewInputControl } from '@wings-shared/form-controls';
import {
  IClasses,
  IOptionValue,
  ISelectOption,
  Utilities,
  IdNameModel,
  SettingsTypeModel,
  IdNameCodeModel,
} from '@wings-shared/core';

interface Props extends IViewInputControl {
  exceptionRules?: PermitExceptionRuleModel[];
  exceptionRuleTempId?: number;
  ruleFilter?: RuleFilterModel;
  value?: IOptionValue;
  classes?: IClasses;
  permitSettingsStore?: PermitSettingsStore;
  permitStore?: PermitStore;
  errors: IdNameModel<string>[];
}

@inject('permitSettingsStore', 'permitStore')
@observer
class PermitExceptionRuleViewControl extends BasePermitExceptionRuleViewControl<Props> {
  private viewRenderer(ruleValues: ISelectOption[], getTagProps?: AutocompleteGetTagProps): ReactNode {
    return ruleValues.map((ruleData: ISelectOption, index: number) => (
      <Chip
        classes={{ root: this.props.classes?.chip }}
        key={ruleData.value as string}
        label={ruleData.label}
        {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
      />
    ));
  }

  private get inputValue(): IOptionValue | IOptionValue[] | null {
    const { fieldKey, value } = this.props;
    const ruleFilter = this.props.ruleFilter as RuleFilterModel;
    const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(ruleFilter);

    switch (fieldKey) {
      case 'permitRequirementType':
        return value || null;
      case 'ruleLogicalOperator':
        const { ruleLogicalOperator } = ruleFilter;
        return Boolean(ruleLogicalOperator?.id) ? ruleLogicalOperator : null;
      case 'name':
        return value || '';
      case 'ruleField':
        if (entityParamConfig) {
          return new SettingsTypeModel({ id: entityParamConfig.id, name: entityParamConfig.entityParameter });
        }

        return null;
      case 'ruleValues':
        const { ruleValues } = ruleFilter;
        if (!Boolean(ruleValues?.length) || !Boolean(entityParamConfig)) {
          return this.hasMultiple ? [] : null;
        }

        const { apiSource, isDropDown } = entityParamConfig;
        switch (apiSource) {
          case PERMIT_RULE_SOURCES.Country:
            return this.getModelBasedRuleValues<CountryModel>(CountryModel, 'isO2Code', ruleValues);
          case PERMIT_RULE_SOURCES.FARType:
            return this.getModelBasedRuleValues<FARTypeModel>(FARTypeModel, 'cappsCode', ruleValues);
          case PERMIT_RULE_SOURCES.Airport:
            if (this.hasMultiple) {
              return this.getModelBasedRuleValues<AirportModel>(AirportModel, 'icaoOrUwaCode', ruleValues);
            }
            return ruleValues[0];
          case PERMIT_RULE_SOURCES.State:
            if (this.hasMultiple) {
              return this.getModelBasedRuleValues<StateModel>(StateModel, 'isoCode', ruleValues);
            }
            return ruleValues[0];
          case PERMIT_RULE_SOURCES.FIR:
            return this.getModelBasedRuleValues<FIRModel>(FIRModel, 'code', ruleValues);
          case PERMIT_RULE_SOURCES.ICAOAerodromeReferenceCode:
            return this.getModelBasedRuleValues<AerodromeReferenceCodeModel>(
              AerodromeReferenceCodeModel,
              'code',
              ruleValues
            );
          case PERMIT_RULE_SOURCES.AirportOfEntry_AOE:
            return this.getModelBasedRuleValues<IdNameCodeModel>(IdNameCodeModel, 'name', ruleValues);
          case PERMIT_RULE_SOURCES.PurposeOfFlight:
          case PERMIT_RULE_SOURCES.NoiseChapter:
          case PERMIT_RULE_SOURCES.Region:
          case PERMIT_RULE_SOURCES.AircraftCategory:
          case PERMIT_RULE_SOURCES.CrossingType:
            return this.getModelBasedRuleValues<SettingsTypeModel>(SettingsTypeModel, 'name', ruleValues);
          default:
            if (isDropDown && !Boolean(apiSource)) {
              return BooleanOperators.find(operator => operator.label === ruleValues[0].ruleValue) || null;
            }

            if (!this.hasMultiple && this.inputEditorType === EDITOR_TYPES.TEXT_FIELD) {
              return ruleValues[0].ruleValue;
            }

            return this.hasMultiple ? ruleValues : ruleValues[0];
        }
      default:
        return (Boolean(ruleFilter) && ruleFilter[fieldKey || '']) || '';
    }
  }

  /* istanbul ignore next */
  private get errorMessage(): string {
    const { fieldKey, ruleFilter, exceptionRuleTempId, errors } = this.props;
    const errorKey: string = `${exceptionRuleTempId}_${ruleFilter?.tempId}_${fieldKey}`;
    return errors.some((err: IdNameModel<string>) => err.id === errorKey) ? 'This Field is required.' : '';
  }

  private get customErrorMessage(): string {
    const { fieldKey, customErrorMessage } = this.props;
    const ruleFilter = this.props.ruleFilter as RuleFilterModel;
    if (fieldKey === 'ruleValues') {
      const entityParamConfig: RuleEntityParameterConfigModel = this.permitSettingsStore.getSelectedEntityParamConfig(
        ruleFilter
      );
      return ruleFilter.hasInvalidRuleValue(entityParamConfig);
    }

    return customErrorMessage || '';
  }

  private get hasApiSource(): boolean {
    const entityParamConfig: RuleEntityParameterConfigModel = this.permitSettingsStore.getSelectedEntityParamConfig(
      this.props.ruleFilter as RuleFilterModel
    );
    return Boolean(entityParamConfig?.apiSource);
  }

  /* istanbul ignore next */
  // get selected option for AUTO Complete
  private getOptionSelected(currentOption: ISelectOption, values: ISelectOption | ISelectOption[]): boolean {
    if (!values) {
      return false;
    }
    if (Array.isArray(values)) {
      return values.map(options => options.value).includes(currentOption.value);
    }

    if (Utilities.isEqual(this.props.fieldKey || '', 'ruleValues')) {
      const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(
        this.props.ruleFilter as RuleFilterModel
      );
      switch (entityParamConfig.apiSource) {
        case PERMIT_RULE_SOURCES.Airport:
          return Utilities.isEqual((currentOption as AirportModel).icaoOrUwaCode, (values as RuleValueModel).code);
        case PERMIT_RULE_SOURCES.State:
          return Utilities.isEqual((currentOption as StateModel).isoCode, (values as RuleValueModel).code);
        default:
          return Utilities.isEqual(currentOption.value, values.value);
      }
    }
    return Utilities.isEqual(currentOption.value, values.value);
  }

  private get editableContent(): ReactNode {
    const { label, value, fieldKey, isDisabled, isHidden, isFullFlex } = this.props;
    const props = this.props as Required<Props>;
    switch (this.inputEditorType) {
      case EDITOR_TYPES.BUTTON:
        return (
          <div className={props.classes.deleteBtn}>
            <SecondaryButton
              variant="contained"
              onClick={() => props.onValueChange(value as IOptionValue, fieldKey || '')}
            >
              {label}
            </SecondaryButton>
          </div>
        );
      case EDITOR_TYPES.DROPDOWN:
      case EDITOR_TYPES.TEXT_FIELD:
      default:
        if (isHidden) {
          return null;
        }
        if (fieldKey === 'ruleValues' && this.hasMultiple) {
          const chipClasses = classNames({
            [props.classes.inputControl]: isFullFlex,
            [props.classes.inputFlex]: !isFullFlex,
          });

          return (
            <div className={chipClasses}>
              <ChipInputControl
                isDisabled={isDisabled}
                options={this.inputOptions?.filter(x => Boolean(x.label))}
                value={this.inputValue as ISelectOption[]}
                label={label}
                freeSolo={!this.hasApiSource}
                disableCloseOnSelect={this.props.disableCloseOnSelect}
                hasError={Boolean(this.errorMessage) || Boolean(this.customErrorMessage)}
                customErrorMessage={this.errorMessage || this.customErrorMessage}
                onChipAddOrRemove={(value: ISelectOption[]) => props.onValueChange(value, fieldKey)}
                onSearch={(searchValue: string) => props.onSearch(searchValue, fieldKey)}
                onBlur={() => props.onBlur(fieldKey, this.inputValue as IOptionValue)}
                onFocus={() => props.onFocus(fieldKey)}
              />
            </div>
          );
        }
        return (
          <ViewInputControl
            options={this.inputOptions}
            isDisabled={isDisabled}
            isExists={this.isAlreadyExists}
            multiple={this.hasMultiple}
            isEditable={true}
            type={this.inputEditorType}
            classes={{ flexRow: props.classes.inputControl }}
            customErrorMessage={this.errorMessage || this.customErrorMessage}
            field={{
              label: label,
              bind: () => null,
              value: this.inputValue,
              key: fieldKey,
              showErrors: () => null,
              rules: label?.includes('*') ? 'required' : '',
            }}
            onValueChange={this.props.onValueChange?.bind(this)}
            renderTags={this.viewRenderer.bind(this)}
            onFocus={this.props.onFocus?.bind(this)}
            onSearch={(searchValue: string) => props.onSearch(searchValue, fieldKey || '')}
            onBlur={this.props.onBlur?.bind(this)}
            getOptionSelected={this.getOptionSelected.bind(this)}
          />
        );
    }
  }

  public render(): ReactNode {
    return this.editableContent;
  }
}

export default withStyles(styles)(PermitExceptionRuleViewControl);
