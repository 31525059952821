import {
  BooleanOperators,
  LogicalOperators,
  PermitExceptionRuleModel,
  PermitSettingsStore,
  PermitStore,
  PERMIT_RULE_SOURCES,
  RuleEntityParameterConfigModel,
  RuleFilterModel,
  RuleValueModel,
} from '../../../Shared';
import { EDITOR_TYPES, IViewInputControl } from '@wings-shared/form-controls';
import { IOptionValue, ISelectOption, Utilities, UnsubscribableComponent, SettingsTypeModel } from '@wings-shared/core';

interface IRuleProps {
  id: number;
  [key: string]: string | number;
}

interface BaseProps extends IViewInputControl {
  exceptionRules?: PermitExceptionRuleModel[];
  exceptionRuleTempId?: number;
  ruleFilter?: RuleFilterModel;
  value?: IOptionValue;
  hasError?: boolean;
  permitSettingsStore?: PermitSettingsStore;
  permitStore?: PermitStore;
}

export class BasePermitExceptionRuleViewControl<Props extends BaseProps> extends UnsubscribableComponent<Props> {
  /* istanbul ignore next */
  protected get permitSettingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  /* istanbul ignore next */
  private get permitStore(): PermitStore {
    return this.props.permitStore as PermitStore;
  }

  protected get isAlreadyExists(): boolean {
    const { fieldKey, exceptionRules, exceptionRuleTempId, value } = this.props;
    switch (fieldKey) {
      case 'name':
        return (exceptionRules as PermitExceptionRuleModel[]).some(
          rule => rule.tempId !== exceptionRuleTempId && Utilities.isEqual(rule.name, value as string)
        );
      default:
        return false;
    }
  }

  protected get hasMultiple(): boolean {
    const { fieldKey, ruleFilter } = this.props;

    if (fieldKey === 'ruleValues' && Boolean(ruleFilter)) {
      return Boolean(ruleFilter?.hasInOperator || ruleFilter?.hasNotInOperator);
    }

    return false;
  }

  protected get inputEditorType(): EDITOR_TYPES {
    const { fieldKey, ruleFilter, type } = this.props;
    switch (fieldKey) {
      case 'ruleValues':
        const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(ruleFilter as RuleFilterModel);
        return Boolean(entityParamConfig?.isDropDown) ? EDITOR_TYPES.DROPDOWN : (type as EDITOR_TYPES);
      default:
        return type as EDITOR_TYPES;
    }
  }

  protected get inputOptions(): ISelectOption[] {
    const { fieldKey } = this.props;
    const ruleFilter = this.props.ruleFilter as RuleFilterModel;
    switch (fieldKey) {
      case 'permitRequirementType':
        return this.permitSettingsStore.permitRequirementTypes;
      case 'ruleLogicalOperator':
        return LogicalOperators;
      case 'ruleEntityType':
        return this.permitSettingsStore.ruleEntities;
      case 'ruleField':
        return this.getRuleFieldOptions(ruleFilter.ruleEntityType);
      case 'ruleConditionalOperator':
        return this.getRuleOperatorOptions(ruleFilter.ruleEntityType, ruleFilter.ruleField);
      case 'ruleValues':
        const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(ruleFilter);
        if (entityParamConfig?.isDropDown) {
          return this.getPermitRuleSourceData(entityParamConfig?.apiSource);
        }
        return [];
      default:
        return [];
    }
  }

  private getPermitRuleSourceData(source: PERMIT_RULE_SOURCES): ISelectOption[] {
    switch (source) {
      case PERMIT_RULE_SOURCES.Country:
        return this.permitStore.countries;
      case PERMIT_RULE_SOURCES.FARType:
        return this.permitSettingsStore.farTypes;
      case PERMIT_RULE_SOURCES.Airport:
        return this.permitStore.wingsAirports;
      case PERMIT_RULE_SOURCES.PurposeOfFlight:
        return this.permitSettingsStore.flightPurposes;
      case PERMIT_RULE_SOURCES.NoiseChapter:
        return this.permitSettingsStore.noiseChapters;
      case PERMIT_RULE_SOURCES.State:
        return this.permitStore.states;
      case PERMIT_RULE_SOURCES.Region:
        return this.permitStore.regions;
      case PERMIT_RULE_SOURCES.FIR:
        return this.permitStore.firs;
      case PERMIT_RULE_SOURCES.ICAOAerodromeReferenceCode:
        return this.permitStore.aerodromeReferenceCodes;
      case PERMIT_RULE_SOURCES.AircraftCategory:
        return this.permitStore.aircraftCategories;
      case PERMIT_RULE_SOURCES.CrossingType:
        return this.permitSettingsStore.crossingTypes;
      case PERMIT_RULE_SOURCES.AirportOfEntry_AOE:
        return this.permitStore.airportOfEntries;
      default:
        return BooleanOperators;
    }
  }

  private getRuleFieldOptions(entity: SettingsTypeModel): ISelectOption[] {
    return (
      this.permitSettingsStore.ruleEntityParameterConfigs
        .filter(({ ruleEntityType }: RuleEntityParameterConfigModel) =>
          Utilities.isEqual(ruleEntityType.name, entity?.name)
        )
        .map(
          (entityParamConfig: RuleEntityParameterConfigModel) =>
            new SettingsTypeModel({ id: entityParamConfig.id, name: entityParamConfig.entityParameter })
        ) || []
    );
  }

  private getRuleOperatorOptions(entity: SettingsTypeModel, field: SettingsTypeModel): ISelectOption[] {
    const operators: SettingsTypeModel[] =
      this.permitSettingsStore.ruleEntityParameterConfigs.find(
        ({ ruleEntityType, entityParameter }: RuleEntityParameterConfigModel) =>
          Utilities.isEqual(ruleEntityType.name, entity?.name) && Utilities.isEqual(entityParameter, field?.name)
      )?.supportedOperators || [];
    return Utilities.customArraySort<SettingsTypeModel>(operators, 'name');
  }

  private getRuleValueId({ ruleValue }: RuleValueModel): number {
    return Utilities.getNumberOrNullValue(ruleValue) as number;
  }

  protected getModelBasedRuleValues<T>(
    TModel: new (...args: IRuleProps[]) => T,
    propertyName: string,
    ruleValues: RuleValueModel[]
  ): T | T[] {
    if (this.hasMultiple) {
      return ruleValues.map(
        (rule: RuleValueModel) => new TModel({ id: this.getRuleValueId(rule), [propertyName]: rule.code })
      );
    }
    return new TModel({
      id: this.getRuleValueId(ruleValues[0]),
      [propertyName]: ruleValues[0].code,
    });
  }
}
