import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { withStyles } from '@material-ui/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { PermitExceptionRuleModel, RuleFilterModel } from '../../../Shared';
import { styles } from './PermitRuleDeleteConfirmDialog.styles';
import { IClasses, IOptionValue } from '@wings-shared/core';
import { EDITOR_TYPES, ViewInputControl, IViewInputControl } from '@wings-shared/form-controls';
import { ConfirmDialog } from '@wings-shared/layout';

interface Props {
  onUpdateExceptionRules: (exceptionRules: PermitExceptionRuleModel[]) => void;
  exceptionRules: PermitExceptionRuleModel[];
  exceptionRuleTempId: number;
  viewMode?: VIEW_MODE;
  classes?: IClasses;
}

const field = {
  isDeleteRuleConfirmed: {
    label: 'I understand that once deleted, this rule cannot be recovered.',
    rules: 'required',
  },
};

@observer
class PermitRuleDeleteConfirmDialog extends BaseUpsertComponent<Props, RuleFilterModel> {
  constructor(p: Props) {
    super(p, field);
  }

  private get checkboxControl(): IViewInputControl {
    return {
      fieldKey: 'isDeleteRuleConfirmed',
      type: EDITOR_TYPES.CHECKBOX,
    };
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    this.getField(fieldKey).set(value);
  }

  @action
  private onYesClick(): void {
    const { exceptionRules, exceptionRuleTempId } = this.props;
    const exceptionRulesModelCopy: PermitExceptionRuleModel[] = [ ...exceptionRules ];
    const exceptionIndex: number = exceptionRules.findIndex(rule => rule.tempId === exceptionRuleTempId);
    
    if (exceptionIndex !== -1) {
      exceptionRulesModelCopy.splice(exceptionIndex, 1);
    }
    this.props.onUpdateExceptionRules([ ...exceptionRulesModelCopy ]);
    ModalStore.close();
  }

  public render(): ReactNode {
    const { isDeleteRuleConfirmed } = this.form.values();
    return (
      <ConfirmDialog
        title="Confirm Delete"
        message="Caution: Deleting this rule will permanently remove its
        associated cases and dependencies. This action cannot be undone. "
        dialogContent={
          <>
            <ViewInputControl
              {...this.checkboxControl}
              isEditable={true}
              field={this.getField(this.checkboxControl.fieldKey || '')}
              onValueChange={(option, fieldKey) => this.onValueChange(option, fieldKey)}
            />
          </>
        }
        yesButton="Delete"
        isDisabledYesButton={!isDeleteRuleConfirmed}
        isDeleteButton={true}
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.onYesClick()}
      />
    );
  }
}

export default withStyles(styles)(PermitRuleDeleteConfirmDialog);
