import React from 'react';
import { finalize, takeUntil } from 'rxjs/operators';
import { action, observable } from 'mobx';
import { Field } from 'mobx-react-form';
import { NavigateFunction } from 'react-router';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { VIEW_MODE, BaseUpsertComponent } from '@wings/shared';
import {
  PermitExceptionRuleModel,
  PermitModel,
  PermitSettingsStore,
  PermitStore,
  RuleFilterModel,
  sidebarOptions,
} from '../../../Shared';
import { observer } from 'mobx-react';
import { IClasses, IOptionValue, UIStore, Utilities } from '@wings-shared/core';
import { ConfirmDialog, SidebarStore } from '@wings-shared/layout';

export interface BaseProps {
  classes?: IClasses;
  navigate?: NavigateFunction;
  viewMode?: VIEW_MODE;
  sidebarStore?: typeof SidebarStore;
  permitStore?: PermitStore;
  permitSettingsStore?: PermitSettingsStore;
  params?: { permitId: number; viewMode: VIEW_MODE };
}

@observer
class PermitUpsert<Props extends BaseProps> extends BaseUpsertComponent<Props, PermitModel> {
  @observable protected permitModel: PermitModel;
  @observable protected isDataChanged: boolean = false;

  constructor(p: Props, fields: Field) {
    super(p, fields);
    const viewMode = this.props.params?.viewMode;
    this.viewMode = (viewMode?.toUpperCase() as VIEW_MODE) || VIEW_MODE.NEW;
    this.permitModel = new PermitModel(this.permitStore?.permitDataModel);
  }

  private get hasExtensionDataEmpty(): boolean {
    const { hasRouteOrAirwayExtension, permitRouteAirwayExtensions } = this.permitModel;
    return !(hasRouteOrAirwayExtension && permitRouteAirwayExtensions?.length) && hasRouteOrAirwayExtension;
  }

  /* istanbul ignore next */
  @action
  protected upsertPermit(): void {
    if (this.hasExtensionDataEmpty) {
      this.showAlert('Permit Routes are required', 'permitRouteAlert');
      return;
    }

    const { permitDocuments } = this.permitModel;
    const docs = permitDocuments.every(x => x.document.id);
    if (permitDocuments?.length && !docs) {
      this.showAlert('Document is required', 'permitDocument');
      return;
    }

    this.setPermitDataChanged(false);
    UIStore.setPageLoader(true);
    this.permitStore
      .upsertPermit(this.permitModel)
      .pipe(
        finalize(() => UIStore.setPageLoader(false)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (updatedModel: PermitModel) => {
          if (!Boolean(this.permitId)) {
            this.form.reset();
            this.setPermitDataChanged(false);
            const basePath: string = `permits/${updatedModel.id}/${VIEW_MODE.EDIT.toLowerCase()}`;
            this.props.sidebarStore?.setNavLinks(sidebarOptions(true, false, VIEW_MODE.EDIT), basePath);
            this.props.navigate && this.props.navigate(`/${basePath}/exceptions`, this.noBlocker);
          }
          const model = new PermitModel({
            ...updatedModel,
            country: this.permitModel.country,
          });
          this.permitStore.setPermitDataModel(model);
          this.permitModel = model;
          this.form.reset();
          this.form.set(model);
          this.setPermitDataChanged(false);
          this.setViewMode(VIEW_MODE.DETAILS);
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  public navigateToPermits(): void {
    this.props.navigate && this.props.navigate('/permits');
  }

  protected onUpsertAction(): void {
    if (!this.isShowConfirmDialog) {
      this.upsertPermit();
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Save"
        message="Are you sure you want to save your changes."
        yesButton="Save"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          this.upsertPermit();
          ModalStore.close();
        }}
      />
    );
  }

  @action
  protected setPermitDataChanged(isChanged: boolean): void {
    this.isDataChanged = isChanged;
  }

  @action
  protected onCancel(): void {
    this.setViewMode(VIEW_MODE.DETAILS);
    this.resetExpandedMode();
    this.setPermitDataChanged(false);
  }

  protected setIsExceptionRuleAndValue(): void {
    const { hasExceptionTextOrRuleExists, isException } = this.permitModel;
    const _isException: IOptionValue = hasExceptionTextOrRuleExists && !isException ? '' : Boolean(isException);
    this.getField('isException').set(_isException);
    this.setFormRules('isException', hasExceptionTextOrRuleExists, 'Is Exception');
  }

  protected setExceptionTextDisabled(isDisabled: boolean): void {
    this.getField('exception').set('disabled', isDisabled);
  }

  /* istanbul ignore next */
  protected get groupTitle(): string {
    const { country } = this.form.values();
    return country?.commonName || 'Country';
  }

  /* istanbul ignore next */
  protected get isShowConfirmDialog(): boolean {
    const { permitDataModel } = this.permitStore;
    if (Boolean(permitDataModel?.exceptionText) && !Boolean(this.permitModel.exceptionText)) {
      return true;
    }
    if (permitDataModel?.permitExceptionRules.length && !this.permitModel.permitExceptionRules.length) {
      return true;
    }
    return false;
  }

  protected get isDetailsView(): boolean {
    return Utilities.isEqual(this.viewMode, VIEW_MODE.DETAILS);
  }

  /* istanbul ignore next */
  protected get permitId(): Number | null {
    const permitId = this.props.params?.permitId;
    return Number(permitId) || null;
  }

  protected get hasError(): boolean {
    const { hasError } = this.form;
    return hasError;
  }

  /* istanbul ignore next */
  protected get isExceptionDataInValid(): boolean {
    return (
      (this.permitModel.isException &&
        (!Boolean(this.permitModel.exceptionText) || !this.permitModel.permitExceptionRules.length)) ||
      (!this.permitModel.isException && this.permitModel.hasExceptionTextOrRuleExists)
    );
  }

  /* istanbul ignore next */
  protected get hasPermitExceptionRuleError(): boolean {
    return (
      this.permitModel.hasInValidPermitExceptionRules ||
      this.permitModel.permitExceptionRules.some(
        (rule: PermitExceptionRuleModel) =>
          Boolean(rule.hasInvalidName) ||
          rule.ruleFilters.some((ruleFilter: RuleFilterModel) =>
            Boolean(ruleFilter.hasInvalidRuleValue(this.settingsStore.getSelectedEntityParamConfig(ruleFilter)))
          )
      )
    );
  }

  /* istanbul ignore next */
  protected get settingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  /* istanbul ignore next */
  protected get permitStore(): PermitStore {
    return this.props.permitStore as PermitStore;
  }
}

export default PermitUpsert;
