import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      flex: 1,
    },
  });
  
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    flex: 1,
  },
}));
  
