import React, { FC, ReactNode, useEffect, useState } from 'react';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { PermitDocumentModel, PermitModel } from '../../../Shared';
import { PermitEditorActions, PermitUpsertV2 } from '../../Components';
import { EntityMapModel, UIStore } from '@wings-shared/core';
import { Collapsable, ConfirmNavigate, DetailsEditorWrapper, TitleContentWrapper } from '@wings-shared/layout';
import { useConfirmDialog, useUnsubscribe } from '@wings-shared/hooks';
import { BaseProps } from '../PermitUpsert/PermitUpsert';
import { fields } from './Fields';
import { EDITOR_TYPES, ViewInputControl } from '@wings-shared/form-controls';
import { VIEW_MODE } from '@wings/shared';
import PermitDocumentV2 from './PermitDocumentV2';

type Props = BaseProps;

const PermitRequirementsV2: FC<Props> = ({ ...props }) => {
  const [ isRowEditing, setIsRowEditing ] = useState(false);
  const unsubscribe = useUnsubscribe();
  const {
    _permitSettingsStore,
    _permitStore,
    isDataChanged,
    useUpsert,
    permitModel,
    navigateToPermits,
    setPermitModel,
    params,
    onUpsertAction,
    hasError,
  } = PermitUpsertV2({ ...props, fields });
  const _useConfirmDialog = useConfirmDialog();

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    forkJoin([
      _permitSettingsStore?.getDocuments(),
      _permitSettingsStore?.getFARTypes(),
      _permitSettingsStore?.getElements(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
    useUpsert.setFormValues(_permitStore?.permitDataModel as PermitModel);
  };

  const upsertData = () => {
    const { permitRequiredElements } = useUpsert.form.values();
    setPermitModel(
      new PermitModel({
        ...permitModel,
        permitRequiredElements: permitRequiredElements.map(({ id, ...rest }) => {
          return new EntityMapModel({ id: Math.floor(id), ...rest });
        }),
      })
    );
    onUpsertAction();
  };

  const updateDocuments = (permitDocuments: PermitDocumentModel[]): void => {
    setPermitModel(
      new PermitModel({
        ..._permitStore?.permitDataModel,
        permitDocuments: permitDocuments.map(({ id, ...rest }) => {
          return new PermitDocumentModel({ id: Math.floor(id), ...rest });
        }),
      })
    );
  };

  const updateRowEditing = (isEditing: boolean): void => {
    setIsRowEditing(isEditing);
  };

  const hasGeneralError = (): boolean => {
    return  isRowEditing;
  };

  const setInitialData = (): void => {
    useUpsert.setViewMode(VIEW_MODE.DETAILS);
    updateRowEditing(false);
    useUpsert.form.reset();
    useUpsert.setFormValues(_permitStore.permitDataModel);
    setPermitModel(
      new PermitModel({
        ..._permitStore.permitDataModel,
      })
    );
    return;
  };

  const onCancel = (): void => {
    const viewMode = params?.viewMode?.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      return _useConfirmDialog.confirmAction(() => setInitialData(), {});
    }
    setInitialData();
    navigateToPermits();
    return;
  };

  const headerActions = (): ReactNode => {
    return (
      <PermitEditorActions
        hasError={hasGeneralError() || UIStore.pageLoading || isRowEditing}
        isDetailsView={useUpsert.isDetailView}
        onCancelClick={() => onCancel()}
        onUpsert={() => upsertData()}
        onSetViewMode={(mode: VIEW_MODE) => useUpsert.setViewMode(mode)}
        isRowEditing={isRowEditing}
      />
    );
  };

  const elements = (): EntityMapModel[] => {
    return _permitSettingsStore?.elements.map(
      x => new EntityMapModel({ name: x.name, entityId: x.id })
    ) as EntityMapModel[];
  };

  return (
    <ConfirmNavigate isBlocker={isDataChanged}>
      <DetailsEditorWrapper headerActions={headerActions()} isEditMode={!useUpsert.isDetailView}>
        <TitleContentWrapper permitTitle={permitModel.permitTitle} title="Requirements">
          <Collapsable title="Elements">
            <ViewInputControl
              isEditable={useUpsert.isEditable || !useUpsert.isDetailView}
              type={EDITOR_TYPES.DROPDOWN}
              multiple={true}
              options={elements()}
              field={useUpsert.getField('permitRequiredElements')}
              onValueChange={(option, fieldKey) => useUpsert.onValueChange(option, fieldKey)}
            />
          </Collapsable>
          <PermitDocumentV2
            isEditable={useUpsert.isEditable || !useUpsert.isDetailView || isRowEditing}
            permitDocuments={permitModel.permitDocuments}
            onDataSave={permitDocument => updateDocuments(permitDocument)}
            onRowEditing={isEditing => updateRowEditing(isEditing)}
          />
        </TitleContentWrapper>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject('permitSettingsStore', 'permitStore')(observer(PermitRequirementsV2));
