import React, { ReactNode } from 'react';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { withStyles, TextareaAutosize, Typography, Tooltip } from '@material-ui/core';
import { VIEW_MODE, ModelStatusOptions, CountryModel, FIRModel } from '@wings/shared';
import { PermitAppliedModel, PermitModel, RouteExtensionModel } from '../../../Shared';
import { styles } from '../PermitUpsert/PermitUpsert.styles';
import { PermitEditorActions, PermitGroupViewInputControls } from '../../Components';
import PermitUpsert, { BaseProps } from '../PermitUpsert/PermitUpsert';
import { fields } from './Fields';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  IAPIGridRequest,
  IOptionValue,
  UIStore,
  Utilities,
  withRouter,
  SettingsTypeModel,
  ViewPermission,
  SourceTypeModel,
  IClasses,
} from '@wings-shared/core';
import RouteAirwayGrid from './RouteAirwayGrid';
import { AuditFields, EDITOR_TYPES, IGroupInputControls } from '@wings-shared/form-controls';
import { DetailsEditorWrapper, TitleContentWrapper, ConfirmNavigate, ConfirmDialog } from '@wings-shared/layout';
import { AddCircleOutlined, Visibility } from '@material-ui/icons';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton, SecondaryButton } from '@uvgo-shared/buttons';

type Props = BaseProps;
@inject('permitStore', 'permitSettingsStore', 'sidebarStore')
@observer
class PermitGeneralUpsert extends PermitUpsert<Props> {
  @observable protected isAlreadyExists: boolean = false;
  @observable private isRowEditing: boolean = false;
  @observable private geoJson: string = '';
  @observable private errorMessage: string = '';
  private readonly alertMessageId: string = 'PermitAlertMessage';

  constructor(p: Props) {
    super(p, fields);
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.setInitialFormData(false);
    UIStore.setPageLoader(true);
    this.loadTypes()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  private loadTypes(): Observable<
    [CountryModel[], FIRModel[], SettingsTypeModel[], SettingsTypeModel[], SettingsTypeModel[], SettingsTypeModel[]]
    > {
    if (this.isEditable) {
      return forkJoin([
        this.permitStore.getCountries(),
        this.permitStore.getFIRs(),
        this.settingsStore.getPermitTypes(),
        this.settingsStore.getPermitAppliedTo(),
        this.settingsStore.getSourceTypes(),
        this.settingsStore.getAccessLevels(),
      ]);
    }
    return of([[], [], [], [], [], []]);
  }

  @action
  private checkIsPermitExists(fieldKey: string): void {
    const { country, permitType } = this.permitModel;
    if (!Boolean(country?.id) || !Boolean(permitType?.id)) {
      this.isAlreadyExists = false;
      return;
    }
    switch (fieldKey) {
      case 'country':
      case 'permitType':
        const request: IAPIGridRequest = {
          filterCollection: JSON.stringify([
            { propertyName: 'Country.CountryId', propertyValue: country.id },
            { propertyName: 'PermitType.PermitTypeId', propertyValue: permitType.id },
          ]),
        };
        UIStore.setPageLoader(true);
        this.permitStore
          .isPermitExists(request, Number(this.permitId))
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => UIStore.setPageLoader(false))
          )
          .subscribe((isExists: boolean) => (this.isAlreadyExists = isExists));
        break;
    }
  }

  @action
  private setInitialFormData(shouldSetDetail: boolean = true): void {
    if (!this.permitId) {
      this.setFormValues(new PermitModel({ id: 0, sourceType: null as any }));
      return;
    }
    const { permitDataModel } = this.permitStore;
    const { permitApplied } = permitDataModel;
    this.permitModel = new PermitModel({ ...permitDataModel });
    this.form.reset();
    this.setFormValues(permitDataModel);
    this.setNauticalMilesRules(permitApplied?.permitAppliedTo.name);
    this.setIsPolygonRules(permitApplied?.permitAppliedTo.name);
    this.geoJson = permitApplied.geoJson;
    if (shouldSetDetail) {
      this.setViewMode(VIEW_MODE.DETAILS);
      this.isAlreadyExists = false;
    }
  }

  @action
  private setViewModeAction(mode: VIEW_MODE): void {
    this.setViewMode(mode);
    UIStore.setPageLoader(true);
    this.loadTypes()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      if (this.form.touched) {
        return ModalStore.open(
          <ConfirmDialog
            title="Confirm Cancellation"
            message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
            yesButton="Yes"
            onNoClick={() => ModalStore.close()}
            onYesClick={() => {
              ModalStore.close();
              this.setInitialFormData();
            }}
          />
        );
      }
      this.setInitialFormData();
      return;
    }
    this.navigateToPermits();
  }

  protected confirmRemoveAllRouteAirwayExtension(fieldKey: string, value: IOptionValue): void {
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Disabling the Route/Airway Extension will remove the
        associated Route/Airway Extenions. Are you sure you want to continue?"
        yesButton="Yes"
        onNoClick={() => {
          ModalStore.close();
          return;
        }}
        onYesClick={() => {
          this.updateRouteExtension([]);
          this.getField(fieldKey).set(value);
          this.updatePermitModel();
          this.checkIsPermitExists(fieldKey);
          ModalStore.close();
        }}
      />
    );
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    switch (fieldKey) {
      case 'permitApplied.permitAppliedTo':
        const _value = value as SettingsTypeModel;
        this.resetPermitAppliedToBasedFields(_value);
        this.getField(fieldKey).set(value);
        this.getField('permitApplied.permitAppliedFIRs').clear();
        break;
      case 'permitApplied.isPolygon':
        this.setIsPolygonValue(value);
        break;
      case 'permitType':
        this.resetPermitTypeBasedFields(value);
        this.getField(fieldKey).set(value);
        break;
      case 'hasRouteOrAirwayExtension':
        const { permitRouteAirwayExtensions } = this.permitModel;
        if (!value && Boolean(permitRouteAirwayExtensions?.length)) {
          this.confirmRemoveAllRouteAirwayExtension(fieldKey, value);
          return;
        }
        this.getField(fieldKey).set(value);
        break;
      default:
        this.getField(fieldKey).set(value);
        break;
    }
    this.updatePermitModel();
    this.checkIsPermitExists(fieldKey);
  }

  @action
  private updatePermitModel(): void {
    const { permitApplied } = this.form.values();
    const { permitDataModel } = this.permitStore;
    this.permitModel = new PermitModel({
      ...permitDataModel,
      ...this.form.values(),
      permitApplied: new PermitAppliedModel({
        ...permitDataModel?.permitApplied,
        ...permitApplied,
        isPolygon: permitApplied.isPolygon || false,
        geoJson: permitApplied.geoJson,
      }),
      permitRouteAirwayExtensions: this.permitModel.permitRouteAirwayExtensions || [],
    });
    this.setPermitDataChanged(true);
  }

  private resetPermitTypeBasedFields(value: IOptionValue): void {
    const _value = value as SettingsTypeModel;
    const isLanding = Utilities.isEqual(_value?.name, 'Landing');
    if (isLanding) {
      this.getField('permitApplied.permitAppliedTo').set(null);
      this.resetExtendedByNMField();
      this.resetPolygonField();
    }
  }

  private resetPermitAppliedToBasedFields(value: SettingsTypeModel): void {
    this.resetPolygonField();
    this.resetExtendedByNMField();
    this.setNauticalMilesRules(value?.name);
    this.setIsPolygonRules(value?.name);
  }

  private resetExtendedByNMField(): void {
    this.getField('permitApplied.extendedByNM').set(null);
    this.setNauticalMilesRules('');
  }

  private resetPolygonField(): void {
    this.getField('permitApplied.isPolygon').set(false);
    this.getField('permitApplied.geoJson').set('');
    this.geoJson = '';
    this.setIsPolygonRules('');
  }

  private setNauticalMilesRules(value: string): void {
    const isNauticalMiles: boolean = this.isLandmassNauticalMiles(value) || this.isLandmassPlusNmPlus(value);
    this.setFormRules('permitApplied.extendedByNM', isNauticalMiles, 'Extended by Nautical Miles');
    if (isNauticalMiles) {
      if (this.isLandmassNauticalMiles(value)) {
        this.resetPolygonField();
      }
      return;
    }
  }

  private setIsPolygonRules(value: string): void {
    const isPolygon: boolean = this.isLandmassPlusNmPlus(value) || this.isPolygonOnly(value);

    this.setFormRules('permitApplied.isPolygon', isPolygon, 'Is Polygon');
    this.setFormRules('permitApplied.geoJson', isPolygon, 'Polygon GEOJSON');
    if (isPolygon) {
      const { permitApplied } = this.form.values();
      this.setIsPolygonValue(permitApplied.isPolygon);
      if (this.isLandmassPlusNmPlus(value)) {
        this.setFormRules('permitApplied.extendedByNM', isPolygon, 'Extended by Nautical Miles');
      }
    }
  }

  private setIsPolygonValue(value: IOptionValue): void {
    this.getField('permitApplied.isPolygon').set(!Boolean(value) ? '' : value);
    if (!Boolean(value)) {
      this.getField('permitApplied.geoJson').set('');
      this.geoJson = '';
    }
  }

  private isLandmassNauticalMiles(value: string): boolean {
    return Utilities.isEqual(value, 'Landmass Plus NM');
  }

  private isLandmassPlusFIRPlus(value: string): boolean {
    return Utilities.isEqual(value, 'Landmass Plus FIR Plus');
  }

  private isLandmassPlus(value: string): boolean {
    return Utilities.isEqual(value, 'Landmass Plus');
  }

  private isLandmassPlusNmPlus(value: string): boolean {
    return Utilities.isEqual(value, 'Landmass Plus NM Plus');
  }

  private isPolygonOnly(value: string): boolean {
    return Utilities.isEqual(value, 'Polygon Only');
  }

  private get isPermitTypeLanding(): boolean {
    const { permitType } = this.form.values();
    return Utilities.isEqual(permitType?.name, 'Landing');
  }

  private get isExtendedByNMDisabled(): boolean {
    const { permitApplied } = this.form.values();
    return (
      this.isLandmassNauticalMiles(permitApplied?.permitAppliedTo?.name) ||
      this.isLandmassPlus(permitApplied?.permitAppliedTo?.name) ||
      this.isLandmassPlusNmPlus(permitApplied?.permitAppliedTo?.name) ||
      this.isLandmassPlusFIRPlus(permitApplied?.permitAppliedTo?.name) ||
      this.isPolygonOnly(permitApplied?.permitAppliedTo?.name)
    );
  }

  private get isPolygonDisabled(): boolean {
    const { permitApplied } = this.form.values();
    return (
      this.isLandmassPlusFIRPlus(permitApplied?.permitAppliedTo?.name) ||
      this.isLandmassPlus(permitApplied?.permitAppliedTo?.name) ||
      this.isPolygonOnly(permitApplied?.permitAppliedTo?.name) ||
      this.isLandmassPlusNmPlus(permitApplied?.permitAppliedTo?.name)
    );
  }

  private get hasGeneralError(): boolean {
    return this.hasError || this.isAlreadyExists || this.isRowEditing;
  }

  private get disableAppliedFIRs(): boolean {
    const permitAppliedTo = this.getField('permitApplied.permitAppliedTo').value?.name;
    return !permitAppliedTo?.includes('FIR');
  }

  @action
  private updateRouteExtension(permitRouteAirwayExtensions: RouteExtensionModel[]): void {
    this.permitModel = new PermitModel({
      ...this.permitModel,
      permitRouteAirwayExtensions: permitRouteAirwayExtensions.map(({ id, ...rest }) => {
        return new RouteExtensionModel({ id: Math.floor(id), ...rest });
      }),
    });
  }

  @action
  private onGeoJsonChange(value: string): void {
    this.errorMessage = '';
    this.geoJson = value;
  }

  /* istanbul ignore next */
  @action
  private updateGeoJson(): void {
    this.errorMessage = '';
    try {
      JSON.parse(this.geoJson);
      // If parsing succeeds, handle the valid JSON data here
      this.getField('permitApplied.geoJson').set(this.geoJson);
      this.updatePermitModel();
      ModalStore.close();
    } catch (error) {
      // If parsing fails, handle the error here
      this.errorMessage = 'Invalid JSON';
      return;
    }
  }

  /* istanbul ignore next */
  private addGeoJson(): void {
    const { permitApplied } = this.form.values();
    const { classes } = this.props;
    ModalStore.open(
      <Dialog
        title="Polygon GEOJSON"
        open={true}
        onClose={() => {
          ModalStore.close();
          this.errorMessage = '';
        }}
        isLoading={() => this.loader.isLoading}
        classes={{ paperSize: classes?.modalWidth, content: classes?.content }}
        dialogContent={() => (
          <TextareaAutosize
            value={this.geoJson}
            className={classes?.textBox}
            minRows={30}
            disabled={!permitApplied.isPolygon || this.isDetailsView}
            maxLength={75000}
            minLength={3}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.onGeoJsonChange(e.target.value)}
          />
        )}
        dialogActions={() => (
          <ViewPermission hasPermission={permitApplied.isPolygon && !this.isDetailsView}>
            <div className={classes?.actionWrapper}>
              <div className={classes?.wrapper}>
                <Typography variant="body2" className={classes?.error}>
                  {this.errorMessage}
                </Typography>
                <span>
                  <em>Count:</em> {this.geoJson?.length}/75000
                </span>
              </div>
              <div className={classes?.buttonWrapper}>
                <PrimaryButton
                  variant="outlined"
                  onClick={() => {
                    this.geoJson = permitApplied.geoJson;
                    ModalStore.close();
                    this.errorMessage = '';
                  }}
                >
                  Cancel
                </PrimaryButton>
                <SecondaryButton
                  variant="contained"
                  onClick={() => this.updateGeoJson()}
                  disabled={!this.geoJson?.length}
                >
                  Save
                </SecondaryButton>
              </div>
            </div>
          </ViewPermission>
        )}
      />
    );
  }

  private profileEndAdornment(): ReactNode {
    const { classes } = this.props;
    const { permitApplied } = this.form.values();

    if (!permitApplied.isPolygon) {
      return <></>;
    }
    return <AddCircleOutlined onClick={() => this.addGeoJson()} className={classes?.buttonStyle} />;
  }

  /* istanbul ignore next */
  private get hasJson(): boolean {
    const { permitApplied } = this.form.values();
    return Boolean(permitApplied.geoJson);
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls[] {
    return [
      {
        title: this.groupTitle,
        inputControls: [
          {
            fieldKey: 'country',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.permitStore.countries,
            isExists: this.isAlreadyExists,
          },
          {
            fieldKey: 'permitType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.permitTypes,
            isExists: this.isAlreadyExists,
          },
          {
            fieldKey: 'isRequired',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'permitApplied.permitAppliedTo',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.permitAppliedTo,
            isDisabled: this.isPermitTypeLanding,
          },
          {
            fieldKey: 'permitApplied.extendedByNM',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isExtendedByNMDisabled,
          },
          {
            fieldKey: 'permitApplied.permitAppliedFIRs',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: this.permitStore.firs,
            isDisabled: this.disableAppliedFIRs,
            getChipLabel: fir => (fir as FIRModel)?.code,
          },
          {
            fieldKey: 'permitApplied.isPolygon',
            type: EDITOR_TYPES.CHECKBOX,
            isDisabled: !this.isPolygonDisabled,
          },
          {
            fieldKey: 'permitApplied.geoJson',
            type: EDITOR_TYPES.TEXT_FIELD,
            isReadOnly: true,
            endAdormentValue: this.profileEndAdornment(),
            isFullFlex: true,
            customLabel: field => {
              if (this.hasJson && this.isDetailView) {
                return (
                  <>
                    <span>{field.label}</span>
                    <Tooltip title="View JSON">
                      <Visibility onClick={() => this.addGeoJson()} className={this.props.classes?.eyeIcon} />
                    </Tooltip>
                  </>
                );
              }
              return field.label;
            },
          },
          {
            fieldKey: 'accessLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.accessLevels,
          },
          {
            fieldKey: 'sourceType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.sourceTypes,
          },
          {
            fieldKey: 'status',
            type: EDITOR_TYPES.DROPDOWN,
            options: ModelStatusOptions,
          },
          {
            fieldKey: 'hasRouteOrAirwayExtension',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
    ];
  }

  private get headerActions(): ReactNode {
    return (
      <PermitEditorActions
        hasError={this.hasGeneralError || UIStore.pageLoading}
        isDetailsView={this.isDetailsView}
        onCancelClick={() => this.onCancel()}
        onUpsert={() => this.onUpsertAction()}
        onSetViewMode={(mode: VIEW_MODE) => this.setViewModeAction(mode)}
      />
    );
  }

  @action
  private updateRowEditing(isEditing: boolean): void {
    this.isRowEditing = isEditing;
  }

  public render(): ReactNode {
    const classes = this.props.classes as IClasses;

    const { hasRouteOrAirwayExtension } = this.form.values();
    return (
      <ConfirmNavigate isBlocker={this.form.touched}>
        <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={!this.isDetailView}>
          <TitleContentWrapper permitTitle={this.permitModel.permitTitle} title="General Information">
            <PermitGroupViewInputControls
              isEditable={this.isEditable}
              groupInputControls={this.groupInputControls}
              onGetField={this.getField.bind(this)}
              onValueChange={this.onValueChange.bind(this)}
            />

            <RouteAirwayGrid
              isEditable={(this.isEditable || !this.isDetailView) && hasRouteOrAirwayExtension}
              permitRouteAirwayExtensions={this.permitModel.permitRouteAirwayExtensions}
              onDataSave={this.updateRouteExtension.bind(this)}
              onRowEditingChange={this.updateRowEditing.bind(this)}
            />
            <AuditFields
              isNew={this.isAddNew}
              isEditable={this.isEditable}
              fieldControls={this.auditFields}
              onGetField={this.getField.bind(this)}
              classes={{ inputControl: classes.auditControl }}
            />
          </TitleContentWrapper>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}
export default withRouter(withStyles(styles)(PermitGeneralUpsert));
export { PermitGeneralUpsert as PurePermitGeneralUpsert };
