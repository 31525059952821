import React, { ReactNode } from 'react';
import { ColDef, GridOptions, GridReadyEvent, ICellEditorParams, RowEditingStartedEvent } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { finalize, takeUntil } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { VIEW_MODE } from '@wings/shared';
import {
  PermitStore,
  PermitSettingsStore,
  PERMIT_FILTERS,
  PermitModuleSecurity,
  PermitValidityModel,
} from '../../../Shared';
import { withStyles } from '@material-ui/core';
import { styles } from '../PermitUpsert/PermitUpsert.styles';
import { AxiosError } from 'axios';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import PermitEditorActions from '../PermitEditorActions/PermitEditorActions';
import { Logger } from '@wings-shared/security';
import {
  IClasses,
  ISelectOption,
  UIStore,
  Utilities,
  regex,
  withRouter,
  GRID_ACTIONS,
  cellStyle,
  rowStyle,
} from '@wings-shared/core';
import {
  ConfirmDialog,
  ConfirmNavigate,
  DetailsEditorWrapper,
  TitleContentWrapper,
  SidebarStore,
} from '@wings-shared/layout';
import {
  AgGridCellEditor,
  AgGridActions,
  AgGridAutoComplete,
  BaseGrid,
  CustomAgGridReact,
  AgGridMasterDetails,
  AgGridCheckBox,
} from '@wings-shared/custom-ag-grid';
import { NavigateFunction } from 'react-router';

interface Props {
  classes?: IClasses;
  sidebarStore?: typeof SidebarStore;
  permitStore?: PermitStore;
  permitSettingsStore?: PermitSettingsStore;
  params?: { permitId: number; viewMode: VIEW_MODE };
  navigate?: NavigateFunction;
}

@inject('permitStore', 'permitSettingsStore', 'sidebarStore')
@observer
class PermitValidity extends BaseGrid<Props, PermitValidityModel, PERMIT_FILTERS> {
  @observable protected viewMode: VIEW_MODE;
  private readonly alertMessageId: string = 'PermitValidityAlertMessage';

  constructor(p: Props) {
    super(p);
    const viewMode = this.props.params?.viewMode;
    this.viewMode = viewMode || VIEW_MODE.NEW;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  private loadInitialData() {
    const permitId = this.props.params?.permitId;
    UIStore.setPageLoader(true);
    this.props.permitSettingsStore
      ?.getPermitValidity(Number(permitId), true)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(permitValidity => (this.data = permitValidity));
  }

  /* istanbul ignore next */
  private loadFlightOperationalCategories(): void {
    UIStore.setPageLoader(true);
    this.permitSettingsStore
      .getFlightOperationalCategories()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  private get isEditable(): boolean {
    return Utilities.isEqual(this.viewMode, VIEW_MODE.EDIT) || Utilities.isEqual(this.viewMode, VIEW_MODE.NEW);
  }

  private get permitTitle(): string {
    return this.props.permitStore?.permitDataModel.permitTitle || '';
  }

  /* istanbul ignore next */
  private get permitSettingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }

    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[1].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertPermitValidity(rowIndex);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveRecord(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  private confirmRemoveRecord(rowIndex: number): void {
    const model: PermitValidityModel = this._getTableItem(rowIndex);
    if (model.id === 0) {
      this.deletePermitValidity(model);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this record?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.deletePermitValidity(model)}
      />
    );
  }

  /* istanbul ignore next */
  private deletePermitValidity(model: PermitValidityModel): void {
    ModalStore.close();
    UIStore.setPageLoader(true);
    this.permitSettingsStore
      .removePermitValidity(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: string) => {
          this._removeTableItems([ model ]);
          this.data = this._getAllTableRows();
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      if (this.isRowEditing) {
        return ModalStore.open(
          <ConfirmDialog
            title="Confirm Cancellation"
            message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
            yesButton="Yes"
            onNoClick={() => ModalStore.close()}
            onYesClick={() => {
              ModalStore.close();
              this.onCancelClick();
            }}
          />
        );
      }
      this.onCancelClick();
      return;
    }
    this.props.navigate && this.props.navigate('/permits');
  }

  private onCancelClick(): void {
    this.viewMode = VIEW_MODE.DETAILS;
    this._setIsRowEditing(false);
    this._setColumnVisible('actionRenderer', false);
  }

  private get isDetailView(): boolean {
    return Utilities.isEqual(this.viewMode, VIEW_MODE.DETAILS);
  }

  // Called from Ag Grid Component
  @action
  public onInputChange({ colDef, data }: ICellEditorParams, value: string): void {
    const { field } = colDef;
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
    this.commonErrorMessage = Utilities.getErrorMessages(this.gridApi).toString();
    this._checkToleranceValidity(field || '', value);
  }

  // Called from Ag Grid Component
  @action
  public onDropDownChange({ colDef, data }: ICellEditorParams, value: ISelectOption): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
    this.commonErrorMessage = Utilities.getErrorMessages(this.gridApi).toString();
  }

  private addPermitValidity(): void {
    const permitId = this.props.params?.permitId;
    this._setColumnVisible('actionRenderer', true);
    const model: PermitValidityModel = new PermitValidityModel({ id: 0, permitId });
    this._addNewItems([ model ], { startEditing: true, colKey: 'toleranceMinus' });
    this.hasError = true;
  }

  /* istanbul ignore next */
  private upsertPermitValidity(rowIndex: number): void {
    const model = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(model.id)) {
      return;
    }
    this.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    this.permitSettingsStore
      .upsertPermitValidity(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: PermitValidityModel) => this._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  }

  private isAlreadyExists(id: number): boolean {
    const isAlreadyExist = this._isAlreadyExists([ 'flightOperationalCategory' ], id);
    if (isAlreadyExist) {
      this.showAlert(
        'Permit Validity is already available for selected Flight Operational Category',
        this.alertMessageId
      );
    }
    return isAlreadyExist;
  }

  /* istanbul ignore next */
  private _checkToleranceValidity(field: string, value: string) {
    if (
      (Utilities.isEqual(field, 'toleranceMinus') || Utilities.isEqual(field, 'tolerancePlus')) &&
      !regex.daysHoursMins.test(value)
    ) {
      this.getComponentInstance(field).setCustomError('Please enter valid input (DD:HHH:MM)');
      this.hasError = true;
    }
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Flight Operational Category',
      field: 'flightOperationalCategory',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Flight Operational Category',
        getAutoCompleteOptions: () => this.permitSettingsStore.flightOperationalCategories,
      },
    },
    {
      headerName: 'Is Same Zulu Day',
      field: 'isSameZuluDay',
      cellRenderer: 'checkBoxRenderer',
      cellEditor: 'checkBoxRenderer',
      maxWidth: 210,
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      headerName: 'Tolerance Minus',
      field: 'toleranceMinus',
      cellEditorParams: {
        placeHolder: 'DD:HHH:MM',
        isRequired: true,
        inputRegex: regex.daysHoursMinsInputMask,
      },
    },
    {
      headerName: 'Tolerance Plus',
      field: 'tolerancePlus',
      cellEditorParams: {
        placeHolder: 'DD:HHH:MM',
        isRequired: true,
        inputRegex: regex.daysHoursMinsInputMask,
      },
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 150,
      minWidth: 130,
      suppressSizeToFit: true,
      suppressNavigable: true,
      suppressMenu: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: this.isEditable,
      gridActionProps: {
        hideActionButtons: !this.isEditable,
        getTooltip: () => this.commonErrorMessage,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      getRowStyle: () => rowStyle(this.isRowEditing, this.isEditable),
      frameworkComponents: {
        customCellEditor: AgGridCellEditor,
        actionRenderer: AgGridActions,
        checkBoxRenderer: AgGridCheckBox,
        customAutoComplete: AgGridAutoComplete,
      },
      onGridReady: (param: GridReadyEvent) => {
        this._onGridReady(param);
        this._setColumnVisible('actionRenderer', this.isEditable);
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        this._onRowEditingStarted(event);
        this.loadFlightOperationalCategories();
      },
    };
  }

  private get headerActions(): ReactNode {
    return (
      <PermitEditorActions
        hasError={UIStore.pageLoading}
        isDetailsView={this.isDetailView}
        hideSaveButton={true}
        onCancelClick={() => this.onCancel()}
        onUpsert={() => null}
        onSetViewMode={(mode: VIEW_MODE) => (this.viewMode = mode)}
      />
    );
  }

  public render(): ReactNode {
    return (
      <ConfirmNavigate isBlocker={this.isRowEditing}>
        <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
          <TitleContentWrapper permitTitle={this.permitTitle} title="Validity">
            <AgGridMasterDetails
              addButtonTitle="Add Validity"
              onAddButtonClick={() => this.addPermitValidity()}
              hasAddPermission={PermitModuleSecurity.isEditable}
              disabled={this.isProcessing || !this.isEditable}
              key={`master-details-${this.isEditable}`}
              resetHeight={true}
            >
              <CustomAgGridReact rowData={this.data} gridOptions={this.gridOptions} isRowEditing={this.isRowEditing} />
            </AgGridMasterDetails>
          </TitleContentWrapper>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}

export default withRouter(withStyles(styles)(PermitValidity));
export { PermitValidity as PurePermitValidity };
