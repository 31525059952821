import { PrimaryButton } from '@uvgo-shared/buttons';
import { VIEW_MODE } from '@wings/shared';
import React, { FC } from 'react';
import { PermitModuleSecurity } from '../../../Shared';
import { ArrowBack } from '@material-ui/icons';
import { ViewPermission } from '@wings-shared/core';
import { CustomLinkButton } from '@wings-shared/layout';

type Props = {
  isDetailsView: boolean;
  hasError: boolean;
  onSetViewMode: (mode: VIEW_MODE) => void;
  onCancelClick: () => void;
  onUpsert: () => void;
  hideSaveButton?: boolean;
  isRowEditing?: boolean;
};

const PermitEditorActions: FC<Props> = ({
  isDetailsView,
  hasError,
  onSetViewMode,
  onCancelClick,
  onUpsert,
  hideSaveButton,
  isRowEditing,
}) => {
  if (isDetailsView) {
    return (
      <>
        <CustomLinkButton to="/permits" title="Permits" startIcon={<ArrowBack />} />
        <ViewPermission hasPermission={PermitModuleSecurity.isEditable}>
          <PrimaryButton variant="contained" onClick={() => onSetViewMode(VIEW_MODE.EDIT)}>
            Edit
          </PrimaryButton>
        </ViewPermission>
      </>
    );
  }
  return (
    <>
      <PrimaryButton variant="outlined" onClick={() => onCancelClick()} disabled={isRowEditing}>
        Cancel
      </PrimaryButton>
      <ViewPermission hasPermission={!hideSaveButton}>
        <PrimaryButton variant="contained" disabled={hasError} onClick={() => onUpsert()}>
          Save
        </PrimaryButton>
      </ViewPermission>
    </>
  );
};

export default PermitEditorActions;

PermitEditorActions.defaultProps = {
  hideSaveButton: false,
};
