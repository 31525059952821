import React, { ReactNode } from 'react';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import { PermitModel, PermitSettingsStore } from '../../../Shared';
import { styles } from './PermitException.styles';
import { BasePermitException } from './BasePermitException';
import { PermitExceptionRule } from '../../Components';
import { IClasses, UIStore } from '@wings-shared/core';
import { CollapsibleWithButton } from '@wings-shared/layout';

interface Props {
  permitModel: PermitModel;
  hasPermitExceptionRuleError: boolean;
  classes?: IClasses;
  permitSettingsStore?: PermitSettingsStore;
  onUpdatePermitModel: (updatedPermitModel: PermitModel) => void;
}

@inject('permitSettingsStore')
@observer
class PermitException extends BasePermitException<Props> {
  /* istanbul ignore next */
  componentDidMount() {
    UIStore.setPageLoader(true);
    forkJoin([
      this.permitSettingsStore.getRuleEntities(),
      this.permitSettingsStore.getPermitRequirementTypes(),
      this.permitSettingsStore.getRuleEntityParameterConfigs(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  private onAddExceptionRuleTemplate(): void {
    this.updateExceptionRulesModel([ ...this.exceptionRules, ...this.defaultExceptionRuleTemplate ]);
  }

  public render(): ReactNode {
    const { permitModel } = this.props;
    const classes = this.props.classes as IClasses;
    return (
      <div className={classes.root}>
        <CollapsibleWithButton
          title="Permit Exceptions"
          buttonText="Add Exception Rule"
          isButtonDisabled={!permitModel.isException || this.props.hasPermitExceptionRuleError}
          onButtonClick={() => this.onAddExceptionRuleTemplate()}
          classes={{ button: classes.button }}
        >
          <PermitExceptionRule
            permitModel={permitModel}
            onUpdatePermitModel={(permit: PermitModel) => this.props.onUpdatePermitModel(permit)}
          />
        </CollapsibleWithButton>
      </div>
    );
  }
}

export default withStyles(styles)(PermitException);
