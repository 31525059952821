import { AirportModel, CountryModel, StateModel, FARTypeModel, FIRModel } from '@wings/shared';
import {
  AerodromeReferenceCodeModel,
  PermitExceptionRuleModel,
  PermitModel,
  PermitSettingsStore,
  PermitStore,
  PERMIT_RULE_SOURCES,
  RuleEntityParameterConfigModel,
  RuleFilterModel,
  RuleValueModel,
} from '../../../Shared';
import {
  IOptionValue,
  Utilities,
  UnsubscribableComponent,
  SettingsTypeModel,
  IdNameCodeModel,
} from '@wings-shared/core';

interface BaseProps {
  permitModel: PermitModel;
  permitStore?: PermitStore;
  permitSettingsStore?: PermitSettingsStore;
  onUpdatePermitModel: (updatedPermitModel: PermitModel) => void;
}

export class BasePermitException<Props extends BaseProps> extends UnsubscribableComponent<Props> {
  /* istanbul ignore next */
  protected get exceptionRules(): PermitExceptionRuleModel[] {
    const { permitExceptionRules } = this.props.permitModel;
    return permitExceptionRules || [];
  }

  /* istanbul ignore next */
  protected get permitSettingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  /* istanbul ignore next */
  protected get permitStore(): PermitStore {
    return this.props.permitStore as PermitStore;
  }

  /* istanbul ignore next */
  protected get defaultExceptionRuleTemplate(): PermitExceptionRuleModel[] {
    return [ new PermitExceptionRuleModel({ ruleFilters: [ new RuleFilterModel() ] }) ];
  }

  protected getUpdatedRuleFilters(
    exceptionRuleFilters: RuleFilterModel[],
    ruleFilter: RuleFilterModel
  ): RuleFilterModel[] {
    return Utilities.updateArray(exceptionRuleFilters, ruleFilter, {
      replace: true,
      predicate: t => t.tempId === ruleFilter.tempId,
    });
  }

  /* istanbul ignore next */
  protected getRuleBasedValue<IOptionValue, K extends IOptionValue>(
    value: IOptionValue | IOptionValue[],
    codePropertyName: string,
    valuePropertyName: string = 'id'
  ): RuleValueModel[] {
    if (!Boolean(value) || (Array.isArray(value) && !Boolean(value.length))) {
      return [];
    }

    let values: K[] = [];
    if (Array.isArray(value) && Boolean(value.length)) {
      values = value as K[];
    }

    if (!Array.isArray(value) && typeof value === 'object') {
      values = [ value as K ];
    }

    return values.map(
      value =>
        new RuleValueModel({
          name: value[codePropertyName],
          code: value[codePropertyName],
          ruleValue: value[valuePropertyName],
        })
    );
  }

  /* istanbul ignore next */
  protected getExceptionRuleValues(
    entityParamConfig: RuleEntityParameterConfigModel,
    value: IOptionValue | IOptionValue[],
    hasInOperator?: boolean
  ): RuleValueModel[] {
    switch (entityParamConfig?.apiSource) {
      case PERMIT_RULE_SOURCES.Country:
        return this.getRuleBasedValue<IOptionValue, CountryModel>(value, 'isO2Code');
      case PERMIT_RULE_SOURCES.FARType:
        return this.getRuleBasedValue<IOptionValue, FARTypeModel>(value, 'cappsCode');
      case PERMIT_RULE_SOURCES.Airport:
        return this.getRuleBasedValue<IOptionValue, AirportModel>(value, 'icaoOrUwaCode', 'icaoOrUwaCode');
      case PERMIT_RULE_SOURCES.State:
        return this.getRuleBasedValue<IOptionValue, StateModel>(value, 'entityCode');
      case PERMIT_RULE_SOURCES.ICAOAerodromeReferenceCode:
        return this.getRuleBasedValue<IOptionValue, AerodromeReferenceCodeModel>(value, 'code');
      case PERMIT_RULE_SOURCES.FIR:
        return this.getRuleBasedValue<IOptionValue, FIRModel>(value, 'code');
      case PERMIT_RULE_SOURCES.AirportOfEntry_AOE:
        return this.getRuleBasedValue<IOptionValue, IdNameCodeModel>(value, 'name');
      case PERMIT_RULE_SOURCES.PurposeOfFlight:
      case PERMIT_RULE_SOURCES.NoiseChapter:
      case PERMIT_RULE_SOURCES.Region:
      case PERMIT_RULE_SOURCES.AircraftCategory:
      case PERMIT_RULE_SOURCES.CrossingType:
        return this.getRuleBasedValue<IOptionValue, SettingsTypeModel>(value, 'name');
      default:
        if (entityParamConfig?.isDropDown) {
          return this.getRuleBasedValue<IOptionValue, RuleValueModel>(value, '', 'name');
        }
        if (hasInOperator) {
          return this.getRuleBasedValue<IOptionValue, RuleValueModel>(value, 'label', 'label');
        }
        return Boolean(value?.toString().trim()) ? [ new RuleValueModel({ ruleValue: value.toString() }) ] : [];
    }
  }

  protected updateExceptionRulesModel(exceptionRules: PermitExceptionRuleModel[]): void {
    this.props.onUpdatePermitModel(
      new PermitModel({
        ...this.props.permitModel,
        permitExceptionRules: [ ...exceptionRules ],
      })
    );
  }
}
