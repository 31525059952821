import React, { ReactNode } from 'react';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
  ICellEditorParams,
  RowEditingStartedEvent,
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { forkJoin } from 'rxjs';
import { action, observable } from 'mobx';
import { finalize, takeUntil } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { ModelStatusOptions, VIEW_MODE } from '@wings/shared';
import {
  PermitStore,
  PermitModel,
  PermitLeadTimeModel,
  PermitSettingsStore,
  PERMIT_FILTERS,
  PermitModuleSecurity,
} from '../../../Shared';
import { withStyles } from '@material-ui/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { styles } from '../PermitUpsert/PermitUpsert.styles';
import { PermitEditorActions } from '..';
import { NavigateFunction } from 'react-router';
import {
  IClasses,
  ISelectOption,
  UIStore,
  Utilities,
  regex,
  withRouter,
  GRID_ACTIONS,
  cellStyle,
  rowStyle,
} from '@wings-shared/core';
import {
  DetailsEditorWrapper,
  TitleContentWrapper,
  ConfirmDialog,
  ConfirmNavigate,
  SidebarStore,
} from '@wings-shared/layout';
import {
  AgGridCellEditor,
  AgGridActions,
  AgGridAutoComplete,
  BaseGrid,
  CustomAgGridReact,
  AgGridMasterDetails,
  AgGridStatusBadge,
} from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  sidebarStore?: typeof SidebarStore;
  permitStore?: PermitStore;
  permitSettingsStore?: PermitSettingsStore;
  navigate?: NavigateFunction;
  params?: { permitId: number; viewMode: VIEW_MODE };
}

@inject('permitStore', 'permitSettingsStore', 'sidebarStore')
@observer
class PermitLeadTimesUpsert extends BaseGrid<Props, PermitLeadTimeModel, PERMIT_FILTERS> {
  private readonly uniqueColumns: string[] = [ 'leadTimeType', 'flightOperationalCategory', 'farType' ];
  @observable protected viewMode: VIEW_MODE;
  @observable protected flightOperationalCategorySelected: boolean = false;
  @observable protected farTypeSelected: boolean = false;

  constructor(p: Props) {
    super(p);
    const viewMode = this.props.params?.viewMode;
    this.viewMode = viewMode || VIEW_MODE.NEW;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.data = [ ...this.permitStore.permitDataModel.permitLeadTimes ];
  }

  private get permitTitle(): string {
    return this.permitStore.permitDataModel.permitTitle;
  }

  private get isEditable(): boolean {
    return Utilities.isEqual(this.viewMode, VIEW_MODE.EDIT) || Utilities.isEqual(this.viewMode, VIEW_MODE.NEW);
  }

  /* istanbul ignore next */
  private get permitSettingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  /* istanbul ignore next */
  private get permitStore(): PermitStore {
    return this.props.permitStore as PermitStore;
  }

  @action
  protected setViewMode(viewMode: VIEW_MODE): void {
    this.viewMode = viewMode;
  }

  /* istanbul ignore next */
  private loadPermitLeadTimeData(): void {
    UIStore.setPageLoader(true);
    forkJoin([
      this.permitSettingsStore.getFlightOperationalCategories(),
      this.permitSettingsStore.getTimeLevelsUOM(),
      this.permitSettingsStore.getLeadTimeTypes(),
      this.permitSettingsStore.getFARTypes(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  @action
  private upsertPermit(): void {
    const updatedModel: PermitModel = new PermitModel({
      ...this.permitStore.permitDataModel,
      permitLeadTimes: [ ...this._getAllTableRows() ],
    });
    UIStore.setPageLoader(true);
    this.permitStore
      .upsertPermit(updatedModel)
      .pipe(
        finalize(() => UIStore.setPageLoader(false)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (updatedModel: PermitModel) => {
          this.permitStore.setPermitDataModel(updatedModel);
          this.data = [ ...updatedModel.permitLeadTimes ];
          this.setViewMode(VIEW_MODE.DETAILS);
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }

    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        const model: PermitLeadTimeModel = this._getTableItem(rowIndex);
        const isExists: boolean = this._getAllTableRows().some(
          (rowModel: PermitLeadTimeModel) =>
            this.isRecordExists(this.uniqueColumns, rowModel, rowIndex) &&
            !Utilities.isEqual(rowModel.tempId, model.tempId)
        );
        if (isExists) {
          this.showAlert(
            'Lead Time Type, Flight Operational Category and FAR Type should be unique.',
            'leadTimeTypeId'
          );
          return;
        }
        this.gridApi.stopEditing();
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmDeletePermitLeadTime(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  /* istanbul ignore next */
  private confirmDeletePermitLeadTime(rowIndex: number): void {
    const model: PermitLeadTimeModel = this._getTableItem(rowIndex);

    if (!Boolean(model.id)) {
      this.removePermitLeadTime(model);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this Permit Lead Time?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          ModalStore.close();
          this.removePermitLeadTime(model);
        }}
      />
    );
  }

  private setInitialData(): void {
    this.viewMode = VIEW_MODE.DETAILS;
    this._setIsRowEditing(false);
    this._setColumnVisible('actionRenderer', false);
    this.data = [ ...this.permitStore.permitDataModel.permitLeadTimes ];
  }

  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      if (this.isRowEditing) {
        return ModalStore.open(
          <ConfirmDialog
            title="Confirm Cancellation"
            message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
            yesButton="Yes"
            onNoClick={() => ModalStore.close()}
            onYesClick={() => {
              ModalStore.close();
              this.setInitialData();
            }}
          />
        );
      }
      this.setInitialData();
      return;
    }
    this.navigateToPermits();
  }

  /* istanbul ignore next */
  private removePermitLeadTime(model: PermitLeadTimeModel): void {
    this._removeTableItems([ model ]);
    this.data = this.data.filter(({ id }) => model.id !== id);
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
    this.commonErrorMessage = Utilities.getErrorMessages(this.gridApi).toString();
  }

  @action
  public onDropDownChange(params: ICellEditorParams, value: ISelectOption): void {
    switch (params.colDef.field) {
      case 'flightOperationalCategory':
        this.getComponentInstance('farType').setValue(null);
        this.flightOperationalCategorySelected = !!value;
        if (value) this.farTypeSelected = false;
        break;
      case 'farType':
        this.getComponentInstance('flightOperationalCategory').setValue(null);
        this.farTypeSelected = !!value;
        if (value) this.flightOperationalCategorySelected = false;
        break;
      default:
        break;
    }

    // Update the state and the error message
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
    this.commonErrorMessage = Utilities.getErrorMessages(this.gridApi).toString();
  }

  private addPermitLeadTime(): void {
    this._setColumnVisible('actionRenderer', true);
    const model: PermitLeadTimeModel = new PermitLeadTimeModel();
    this._addNewItems([ model ], { startEditing: true, colKey: 'leadTimeType' });
    this.hasError = true;
  }

  private setDisabled(data: PermitLeadTimeModel): void {
    if (data?.flightOperationalCategory) {
      this.flightOperationalCategorySelected = !!data?.flightOperationalCategory?.label;
    } else if (data?.farType) {
      this.farTypeSelected = !!data?.farType?.label;
    }
  }

  /* istanbul ignore next */
  // 52762 set TimeLevelUOM rule based on the leadTime and maxLeadTime value
  private get isTimeLevelUOMRequired(): boolean {
    const leadTimeValue: string = this.getInstanceValue('leadTimeValue');
    const maxLeadTimeValue: string = this.getInstanceValue('maxLeadTimeValue');
    return Boolean(leadTimeValue || maxLeadTimeValue);
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Lead Time Type',
      field: 'leadTimeType',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Select Lead Time Type',
        getAutoCompleteOptions: () => this.permitSettingsStore.leadTimeTypes,
      },
    },
    {
      headerName: 'Lead Time',
      field: 'leadTimeValue',
      cellEditor: 'customCellEditor',
      cellEditorParams: {
        rules: `regex:${regex.numeric}`,
      },
    },
    {
      headerName: 'Max Lead Time',
      field: 'maxLeadTimeValue',
      cellEditor: 'customCellEditor',
      cellEditorParams: {
        rules: `regex:${regex.numeric}`,
      },
    },
    {
      headerName: 'Time Level UOM',
      field: 'timeLevelUOM',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      cellEditorParams: {
        isRequired: () => this.isTimeLevelUOMRequired,
        placeHolder: 'Select Time Level UOM',
        getAutoCompleteOptions: () => this.permitSettingsStore.timeLevelsUOM,
      },
    },
    {
      headerName: 'Flight Operational Category',
      field: 'flightOperationalCategory',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      cellEditorParams: {
        isRequired: () => !this.farTypeSelected && !this.flightOperationalCategorySelected,
        placeHolder: 'Flight Operational Category',
        getAutoCompleteOptions: () => this.permitSettingsStore.flightOperationalCategories,
        getDisableState: () => this.farTypeSelected,
      },
    },
    {
      headerName: 'FAR Type',
      field: 'farType',
      cellEditor: 'customAutoComplete',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      cellEditorParams: {
        getDisableState: () => this.flightOperationalCategorySelected,
        isRequired: () => !this.flightOperationalCategorySelected && !this.farTypeSelected,
        placeHolder: 'FAR Type',
        getAutoCompleteOptions: () => this.permitSettingsStore.farTypes,
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      cellEditor: 'customAutoComplete',
      cellRenderer: 'statusRenderer',
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Status',
        getAutoCompleteOptions: () => ModelStatusOptions,
      },
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 150,
      minWidth: 130,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      suppressMenu: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: this.isEditable,
      gridActionProps: {
        hideActionButtons: !this.isEditable,
        showDeleteButton: true,
        getTooltip: () => this.commonErrorMessage,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      getRowStyle: () => rowStyle(this.isRowEditing, this.isEditable),
      frameworkComponents: {
        customCellEditor: AgGridCellEditor,
        actionRenderer: AgGridActions,
        customAutoComplete: AgGridAutoComplete,
        statusRenderer: AgGridStatusBadge,
      },
      onGridReady: (param: GridReadyEvent) => {
        this._onGridReady(param);
        this._setColumnVisible('actionRenderer', this.isEditable);
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        this._onRowEditingStarted(event);
        this.loadPermitLeadTimeData();
        this.setDisabled(event?.data);
      },
    };
  }

  /* istanbul ignore next */
  public navigateToPermits(): void {
    this.props.navigate && this.props.navigate('/permits');
  }

  private get isDetailView(): boolean {
    return Utilities.isEqual(this.viewMode, VIEW_MODE.DETAILS);
  }

  private get headerActions(): ReactNode {
    return (
      <PermitEditorActions
        hasError={this.isRowEditing || UIStore.pageLoading}
        isDetailsView={this.isDetailView}
        onCancelClick={() => this.onCancel()}
        onUpsert={() => this.upsertPermit()}
        onSetViewMode={(mode: VIEW_MODE) => (this.viewMode = mode)}
      />
    );
  }

  public render(): ReactNode {
    return (
      <ConfirmNavigate isBlocker={this.isRowEditing}>
        <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={!this.isDetailView}>
          <TitleContentWrapper permitTitle={this.permitTitle} title="Lead Times">
            <AgGridMasterDetails
              addButtonTitle="Add Permit Lead Time"
              onAddButtonClick={() => this.addPermitLeadTime()}
              hasAddPermission={PermitModuleSecurity.isEditable}
              disabled={this.isProcessing || !this.isEditable}
              key={`master-details-${this.isEditable}`}
              resetHeight={true}
            >
              <CustomAgGridReact rowData={this.data} gridOptions={this.gridOptions} isRowEditing={this.isRowEditing} />
            </AgGridMasterDetails>
          </TitleContentWrapper>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}

export default withRouter(withStyles(styles)(PermitLeadTimesUpsert));
export { PermitLeadTimesUpsert as PurePermitLeadTimesUpsert };
