import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { forkJoin } from 'rxjs';
import { action, observable } from 'mobx';
import { TextareaAutosize, withStyles, Typography } from '@material-ui/core';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { styles } from './ExecuteRule.styles';
import { IAPIPermit, PermitModel, PermitSettingsStore, PermitStore } from '../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { fields } from './Fields';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { IAPIGridRequest, IClasses, IOptionValue, UIStore, Utilities } from '@wings-shared/core';
import { EDITOR_TYPES, ViewInputControl, IViewInputControl } from '@wings-shared/form-controls';

interface Props {
  classes?: IClasses;
  viewMode?: VIEW_MODE;
  permitStore?: PermitStore;
  permitSettingsStore?: PermitSettingsStore;
}

@inject('permitStore', 'permitSettingsStore')
@observer
class ExecuteRule extends BaseUpsertComponent<Props, PermitModel> {
  @observable private ruleAttributeJson: string = '';
  @observable private executedResultJson: string = '';
  @observable private alertMessageId: string = 'executeAlertId';

  constructor(p: Props) {
    super(p, fields);
    this.viewMode = VIEW_MODE.EDIT;
  }

  /* istanbul ignore next */
  componentDidMount(): void {
    UIStore.setPageLoader(true);
    forkJoin([ this.permitStore.getCountries(), this.settingsStore.getPermitTypes() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  private get settingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  /* istanbul ignore next */
  private get permitStore(): PermitStore {
    return this.props.permitStore as PermitStore;
  }

  /* istanbul ignore next */
  private get executeRuleInputControls(): IViewInputControl[] {
    return [
      {
        fieldKey: 'country',
        type: EDITOR_TYPES.DROPDOWN,
        options: this.permitStore.countries,
      },
      {
        fieldKey: 'permitType',
        type: EDITOR_TYPES.DROPDOWN,
        options: this.settingsStore.permitTypes,
      },
    ];
  }

  @action
  private onRuleAttributeChange(value: string): void {
    this.ruleAttributeJson = value;
  }

  @action
  private reset(): void {
    const { permits } = this.permitStore;
    if (permits?.length > 0) {
      this.ruleAttributeJson = JSON.stringify(permits[0].permitExceptionRuleObject, null, 2);
      this.executedResultJson = '';
    }
  }

  /* istanbul ignore next */
  @action
  private executedPermit(): void {
    const { country, permitType } = this.form.values();
    UIStore.setPageLoader(true);
    this.props.permitStore
      ?.executePermits(country.isO2Code, permitType.id, Utilities.parseJSON(this.ruleAttributeJson))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((permits: IAPIPermit[]) => {
        if (permits?.length > 0) {
          this.executedResultJson = JSON.stringify(permits[0], null, 2);
          return;
        }
        this.executedResultJson = '';
      });
  }

  /* istanbul ignore next */
  @action
  private getPermitRequiredAttribute(): void {
    const { country, permitType } = this.form.values();

    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([
        { propertyName: 'Country.CountryId', propertyValue: country.id },
        { propertyName: 'PermitType.PermitTypeId', propertyValue: permitType.id },
      ]),
    };

    const specifiedFields: string[] = [
      'PermitExceptionRules.RuleFilters.RuleEntityType',
      'PermitExceptionRules.RuleFilters.PropertyName',
    ];

    this.executedResultJson = '';
    UIStore.setPageLoader(true);
    this.permitStore
      .getPermits(request, specifiedFields)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((permits: PermitModel[]) => {
        if (permits?.length > 0) {
          this.ruleAttributeJson = JSON.stringify(permits[0].permitExceptionRuleObject, null, 2);
          return;
        }
        this.ruleAttributeJson = '';
      });
  }

  public render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        <div className={classes.mainContainer}>
          <div className={classes.boxSection}>
            {this.executeRuleInputControls
              .filter(inputControl => !inputControl.isHidden)
              .map((inputControl: IViewInputControl, index: number) => (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  isEditable={true}
                  field={this.getField(inputControl.fieldKey || '')}
                  onValueChange={(option: IOptionValue, fieldKey: string) => this.onValueChange(option, fieldKey)}
                />
              ))}
            <div>
              <PrimaryButton
                variant="contained"
                disabled={!this.form.isValid}
                onClick={() => this.getPermitRequiredAttribute()}
              >
                Get Permit Rule Attributes
              </PrimaryButton>
            </div>
          </div>
          <div className={classes.boxSection}>
            <Typography component="h2">Permit Rule Attributes</Typography>
            <TextareaAutosize
              value={this.ruleAttributeJson}
              className={classes.textBox}
              rowsMin={45}
              rowsMax={45}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.onRuleAttributeChange(e.target.value)}
            />
            <div className={classes.mainContainer}>
              <PrimaryButton variant="contained" className={classes.executeRuleBtn} onClick={() => this.reset()}>
                Reset To Default
              </PrimaryButton>
              <PrimaryButton
                variant="contained"
                className={classes.executeRuleBtn}
                disabled={!Boolean(this.ruleAttributeJson) || !Utilities.isValidJsonString(this.ruleAttributeJson)}
                onClick={() => this.executedPermit()}
              >
                Execute Rule
              </PrimaryButton>
            </div>
          </div>
          <div className={classes.boxSection}>
            <Typography component="h2">Executed Result</Typography>
            <TextareaAutosize
              value={this.executedResultJson}
              className={classes.textBox}
              disabled={true}
              rowsMin={45}
              rowsMax={45}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ExecuteRule);
