import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { Route, Routes } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { PermitModel, PermitStore, sidebarOptions } from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  PermitGeneralUpsert,
  PermitExceptionUpsert,
  PermitLeadTimesUpsert,
  PermitValidity,
  AdditionalInfo,
  PermitRequirements,
  DMNotes,
} from '../../Components';
import { NavigateFunction } from 'react-router';
import { PermitModuleSecurity } from '../../../Shared/Tools';
import {
  IAPIGridRequest,
  UIStore,
  Utilities,
  withRouter,
  ViewPermission,
  UnsubscribableComponent,
} from '@wings-shared/core';
import { ProtectedRoute, SidebarStore } from '@wings-shared/layout';

interface RouteProps {
  sidebarStore?: typeof SidebarStore;
  permitStore?: PermitStore;
  params?: { viewMode: VIEW_MODE; permitId: number };
  navigate?: NavigateFunction;
}

@inject('sidebarStore', 'permitStore')
@observer
export class PermitRouteModule<Props extends RouteProps> extends UnsubscribableComponent<Props> {
  constructor(p) {
    super(p);
    if (!PermitModuleSecurity.isEditable) {
      this.props.navigate && this.props.navigate(`/permits/${this.permitId}/${VIEW_MODE.DETAILS.toLocaleLowerCase()}`);
    }
  }

  componentDidMount() {
    if (Boolean(this.permitId)) {
      this.loadPermitById();
      return;
    }
    this.setHasLoaded(true);
    this.props.sidebarStore?.setNavLinks(
      sidebarOptions(true, true, VIEW_MODE.NEW),
      `permits/${VIEW_MODE.NEW.toLowerCase()}`
    );
  }

  componentWillUnmount() {
    this.props.permitStore?.setPermitDataModel(new PermitModel());
    this.props.sidebarStore?.setNavLinks(sidebarOptions(false), 'permits');
  }

  /* istanbul ignore next */
  private loadPermitById(): void {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: 10,
      filterCollection: JSON.stringify([{ propertyName: 'PermitId', propertyValue: this.permitId }]),
    };
    UIStore.setPageLoader(true);
    this.props.permitStore
      ?.getPermits(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(() => {
        this.props.permitStore?.setPermitDataModel(new PermitModel({ ...this.permitDataModel }));
        this.setHasLoaded(true);
        this.props.sidebarStore?.setNavLinks(
          sidebarOptions(true, false, VIEW_MODE.EDIT),
          `permits/${this.permitId}/${this.viewMode}`
        );
      });
  }

  /* istanbul ignore next */
  private get permitId(): Number {
    const { params } = this.props;
    return Utilities.getNumberOrNullValue(params?.permitId || '') as number;
  }

  /* istanbul ignore next */
  private get permitDataModel(): PermitModel {
    return this.props.permitStore?.permits.find((permit: PermitModel) => permit.id === this.permitId) as PermitModel;
  }

  private get viewMode(): string {
    return this.props.params?.viewMode?.toLowerCase() || '';
  }

  public render(): ReactNode {
    return (
      <ViewPermission hasPermission={this.hasLoaded}>
        <Routes>
          <Route index element={<PermitGeneralUpsert />} key="PermitGeneralUpsert" />
          <Route path="exceptions" element={<PermitExceptionUpsert />} key="permitExceptions" />
          <Route path="lead-times" element={<PermitLeadTimesUpsert />} key="permitLeadTimes" />
          <Route path="validity" element={<PermitValidity />} key="permitValidity" />
          <Route path="additional-info" element={<AdditionalInfo />} key="permitAdditionalInfo" />
          <Route path="requirements" element={<PermitRequirements />} key="permitRequirements" />
          <Route path="dm-note" key="dm-note" element={<DMNotes />} />
        </Routes>
      </ViewPermission>
    );
  }
}

export default withRouter(PermitRouteModule);
