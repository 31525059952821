import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { styles as commonStyles } from '../PermitUpsert/PermitUpsert.styles';

export const styles = (theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    filledError: {
      color: '#cd263c',
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  ...commonStyles(theme),
  filledError: {
    color: '#cd263c',
  },
}));
