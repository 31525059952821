import { ColDef, ColGroupDef, GridOptions, SortChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { finalize, takeUntil } from 'rxjs/operators';
import { withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AuditHistory, baseApiPath, VIEW_MODE } from '@wings/shared';
import {
  AgGridActions,
  AgGridCheckBox,
  AgGridGroupHeader,
  AgGridStatusBadge,
  AgGridTextArea,
  BaseGrid,
  CustomAgGridReact,
} from '@wings-shared/custom-ag-grid';
import { PERMIT_AUDIT_MODULES, PERMIT_FILTERS, PermitModel, PermitModuleSecurity } from '../Shared';
import { PermitSettingsStore, PermitStore } from '../Shared/Stores';
import { styles } from './Core.module.styles';
import { permitsGridFilters } from './fields';
import {
  GridPagination,
  IAPIGridRequest,
  IClasses,
  UIStore,
  Utilities,
  ViewPermission,
  SORTING_DIRECTION,
  SearchStore,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
  IGridSortFilter,
} from '@wings-shared/core';
import { CustomLinkButton } from '@wings-shared/layout';
import { EDITOR_TYPES, SearchHeader } from '@wings-shared/form-controls';

interface Props {
  permitStore?: PermitStore;
  permitSettingsStore?: PermitSettingsStore;
  classes?: IClasses;
}

const filtersSetup: IBaseGridFilterSetup<PERMIT_FILTERS> = {
  defaultPlaceHolder: 'Search by Country',
  filterTypesOptions: [ PERMIT_FILTERS.COUNTRY ],
  defaultFilterType: PERMIT_FILTERS.COUNTRY,
  apiFilterDictionary: permitsGridFilters,
};

@inject('permitStore', 'permitSettingsStore')
@observer
class CoreModule extends BaseGrid<Props, PermitModel, PERMIT_FILTERS> {
  private readonly settingDetailsGroup: string = 'settingDetails';

  constructor(props) {
    super(props, filtersSetup);
    this.sortFilters = [{ sort: SORTING_DIRECTION.ASCENDING, colId: 'country' }];
  }

  componentDidMount() {
    this.loadPermits();
    this.onAdvanceSearch$().subscribe(() => this.loadPermits());
    this.setMiniFilterTextDebounce();
  }

  /* istanbul ignore next */
  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.setclientSearchValue();
  }

  /* istanbul ignore next */
  private loadPermits(pageRequest?: IAPIGridRequest): void {
    const request: IAPIGridRequest = {
      ...pageRequest,
      ...this._searchFilters,
      ...this._sortFilters,
      ...this._gridAPIAdvancedFilterCollection,
      ...this._gridAPIAdvancedSearchCollection,
    };

    if (this.searchValue) {
      const result = JSON.parse(this._searchFilters?.searchCollection || '');
      request.searchCollection = JSON.stringify(
        result.concat(Utilities.getFilter('Country.Code', this.searchValue as string, 'or'))
      );
    }
    UIStore.setPageLoader(true);
    this.permitStore
      .loadPermits(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          this.data = response.results;
          this.pagination = new GridPagination({ ...response });
          this.gridAdvancedSearchFilterApplied();
          const { clientSearchValue } = SearchStore;
          const { selectedOption, searchValue } = clientSearchValue;
          if (searchValue) {
            this._setSelectedOption((selectedOption as PERMIT_FILTERS) || PERMIT_FILTERS.COUNTRY);
            this._setSearchValue(searchValue);
            this.searchHeaderRef.current?.setSearchValue(searchValue);
            SearchStore.setclientSearchValue();
            return;
          }
          SearchStore.setclientSearchValue();
        },
      });
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (this.searchValue) {
      const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
      SearchStore.setclientSearchValue(clientSearchValue as any);
    }
    if (gridAction === GRID_ACTIONS.AUDIT) {
      const model: PermitModel = this._getTableItem(rowIndex);
      ModalStore.open(
        <AuditHistory
          title={model.permitTitle}
          entityId={model.id}
          entityType={PERMIT_AUDIT_MODULES.PERMIT}
          baseUrl={baseApiPath.permits}
        />
      );
    }
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Country',
      field: 'country',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'country', 2) },
    },
    {
      headerName: 'Permit Type',
      field: 'permitType',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name || '',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'permitType', 2) },
    },
    {
      headerName: 'Is Required',
      field: 'isRequired',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      headerName: 'Is Exception',
      field: 'isException',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      headerName: 'Exception',
      field: 'exception',
      cellRenderer: 'customTextAreaEditor',
      minWidth: 280,
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'exception', 2) },
      cellRendererParams: {
        readOnly: true,
        editorType: EDITOR_TYPES.RICH_TEXT_EDITOR,
      },
    },
    {
      headerName: 'Permit Applied To',
      field: 'permitApplied.permitAppliedTo',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'permitApplied.permitAppliedTo', 2) },
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name || '',
    },
    {
      headerName: 'Extended by Nautical Miles',
      field: 'permitApplied.extendedByNM',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'permitApplied.extendedByNM', 1) },
    },
    {
      headerName: 'Is Polygon',
      field: 'permitApplied.isPolygon',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      groupId: this.settingDetailsGroup,
      children: [
        {
          headerName: 'Access Level',
          headerComponent: 'customHeader',
          field: 'accessLevel',
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
        },
        {
          headerName: 'Source',
          columnGroupShow: 'open',
          field: 'sourceType',
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
        },
        {
          headerName: 'Status',
          field: 'status',
          cellRenderer: 'statusRenderer',
          columnGroupShow: 'open',
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
        },
      ],
    },
    ...this.auditFields,
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      suppressSizeToFit: true,
      minWidth: 140,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !PermitModuleSecurity.isEditable,
            to: ({ data }) => `${data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
            action: GRID_ACTIONS.EDIT,
          },
          {
            title: 'Details',
            to: ({ data }) => `${data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
            action: GRID_ACTIONS.DETAILS,
          },
          { title: 'Audit', action: GRID_ACTIONS.AUDIT },
        ],
      },
    },
  ];

  /* istanbul ignore next */
  private get permitStore(): PermitStore {
    return this.props.permitStore as PermitStore;
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      isEditable: false,
      columnDefs: this.columnDefs,
    });

    return {
      ...baseOptions,
      pagination: false,
      suppressClickEdit: true,
      groupHeaderHeight: 0,
      suppressCellSelection: true,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      isExternalFilterPresent: () => false,
      onFilterChanged: () => this.gridAdvancedSearchFilters.length === 0 && this.loadPermits(),
      onSortChanged: ({ api }: SortChangedEvent) => {
        this.sortFilters = api.getSortModel() as IGridSortFilter[];
        this.loadPermits();
      },
      doesExternalFilterPass: node => {
        const { country } = node.data as PermitModel;
        return this._isFilterPass({
          [PERMIT_FILTERS.COUNTRY]: country?.isO2Code,
        });
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        checkBoxRenderer: AgGridCheckBox,
        customHeader: AgGridGroupHeader,
        customTextAreaEditor: AgGridTextArea,
        statusRenderer: AgGridStatusBadge,
      },
    };
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={PermitModuleSecurity.isEditable}>
        <CustomLinkButton variant="contained" startIcon={<AddIcon />} to="new" title="Add Permit" />
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          rightContent={this.rightContent}
          onSearchTypeChange={option => this._setSelectedOption(option as PERMIT_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          expandCollapse={() => this.autoSizeColumns()}
          isDisabled={Boolean(this.gridAdvancedSearchFilters.length)}
          onResetFilterClick={() => this.onFilterResetClickHandler()}
        />
        <CustomAgGridReact
          rowData={this.data}
          gridOptions={this.gridOptions}
          serverPagination={true}
          paginationData={this.pagination}
          onPaginationChange={request => this.loadPermits(request)}
        />
      </>
    );
  }
}

export default withStyles(styles)(CoreModule);
