import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { VIEW_MODE } from '@wings/shared';
import { PermitSettingsStore, PermitModel, RevisionTriggerModel } from '../../../Shared';
import { withStyles } from '@material-ui/core';
import { styles } from './AdditionalInfo.styles';
import { fields } from './Fields';
import PermitUpsert, { BaseProps } from '../PermitUpsert/PermitUpsert';
import PermitEditorActions from '../PermitEditorActions/PermitEditorActions';
import { PermitAdditionalInfoModel } from '../../../Shared/Models/PermitAdditionalInfo.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IClasses, IOptionValue, UIStore, withRouter } from '@wings-shared/core';
import { EDITOR_TYPES, ViewInputControlsGroup, IGroupInputControls } from '@wings-shared/form-controls';
import { ConfirmDialog, ConfirmNavigate, DetailsEditorWrapper, TitleContentWrapper } from '@wings-shared/layout';
import RevisionTriggerGrid from './RevisionTriggerGrid';

type Props = BaseProps;

@inject('permitStore', 'permitSettingsStore', 'sidebarStore')
@observer
class AdditionalInfo extends PermitUpsert<Props> {
  @observable private isRowEditing: boolean = false;
  @observable protected viewMode: VIEW_MODE;

  constructor(p: Props) {
    super(p, fields);
  }

  componentDidMount() {
    this.setInitialFormData(false);
  }

  private get permitSettingsStore(): PermitSettingsStore {
    return this.props.permitSettingsStore as PermitSettingsStore;
  }

  @action
  private setInitialFormData(shouldSetDetail: boolean = true): void {
    const { permitDataModel } = this.permitStore;
    this.permitModel = new PermitModel({ ...permitDataModel });
    this.form.reset();
    this.setPermitDataChanged(false);
    this.setFormValues(permitDataModel);
    if (shouldSetDetail) this.setViewMode(VIEW_MODE.DETAILS);
  }

  /* istanbul ignore next */
  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      if (this.form.touched || this.isDataChanged) {
        return ModalStore.open(
          <ConfirmDialog
            title="Confirm Cancellation"
            message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
            yesButton="Yes"
            onNoClick={() => ModalStore.close()}
            onYesClick={() => {
              ModalStore.close();
              this.setInitialFormData();
            }}
          />
        );
      }
      this.setInitialFormData();
      return;
    }
    this.navigateToPermits();
  }

  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    this.getField(fieldKey).set(value);
    this.updatePermitModel();
  }

  protected onFocus(fieldKey: string): void {
    switch (fieldKey) {
      case 'appliedPermitPrerequisiteType':
        this.observeSearch(this.permitSettingsStore.getpermitPrerequisiteTypes());
        break;
      case 'appliedBlanketValidityType':
        this.observeSearch(this.permitSettingsStore.getBlanketValidityTypes());
        break;
      case 'appliedPermitDiplomaticType':
        this.observeSearch(this.permitSettingsStore.getPermitDiplomaticTypes());
        break;
      case 'appliedPermitNumberExceptionType':
        this.observeSearch(this.permitSettingsStore.getPermitNumberExceptions());
        break;
      default:
        break;
    }
  }

  /* istanbul ignore next */
  @action
  private updatePermitModel(): void {
    const { permitAdditionalInfo } = this.form.values();
    const { permitDataModel } = this.permitStore;
    this.permitModel = new PermitModel({
      ...permitDataModel,
      ...this.form.values(),
      permitAdditionalInfo: new PermitAdditionalInfoModel({
        ...permitDataModel?.permitAdditionalInfo,
        ...permitAdditionalInfo,
      }),
    });
    this.setPermitDataChanged(true);
  }

  /* istanbul ignore next */
  @action
  private updateRevisions(revisions: RevisionTriggerModel[]): void {
    this.setPermitDataChanged(true);
    this.permitModel = new PermitModel({
      ...this.permitModel,
      permitAdditionalInfo: new PermitAdditionalInfoModel({
        ...this.permitModel?.permitAdditionalInfo,
        permitAdditionalInfoRevisions: revisions.map(({ id, ...rest }) => {
          return new RevisionTriggerModel({ id: Math.floor(id), ...rest });
        }),
      }),
    });
  }

  @action
  private updateRowEditing(isEditing: boolean): void {
    this.isRowEditing = isEditing;
  }

  /* istanbul ignore next */
  private get inputControls(): IGroupInputControls[] {
    const classNames = this.props.classes as IClasses;
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'permitAdditionalInfo.permitIssuanceAuthority',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'permitAdditionalInfo.appliedPermitPrerequisiteType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.permitSettingsStore.permitPrerequisiteTypes,
            multiple: true,
          },
          {
            fieldKey: 'permitAdditionalInfo.isBlanketAvailable',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.appliedBlanketValidityType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.permitSettingsStore.blanketValidityTypes,
            multiple: true,
          },
          {
            fieldKey: 'permitAdditionalInfo.appliedPermitDiplomaticType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.permitSettingsStore.permitDiplomaticTypes,
            multiple: true,
          },
          {
            fieldKey: 'permitAdditionalInfo.isDirectToCAA',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isATCFollowUp',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isPermitNumberIssued',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.appliedPermitNumberExceptionType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.permitSettingsStore.permitNumberExceptions,
            multiple: true,
          },
          {
            fieldKey: 'permitAdditionalInfo.isBlanketPermitNumberIssued',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isShortNoticePermitAvailability',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isRampCheckPossible',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isFAOCRequired',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isHandlerCoordinationRequired',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          // new fields
          {
            fieldKey: 'permitAdditionalInfo.isCAAPermitFeeApplied',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            excludeEmptyOption: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isPermitFeeNonRefundable',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            excludeEmptyOption: true,
          },
          {
            fieldKey: 'permitAdditionalInfo.isWindowPermitsAllowed',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
          },
          {
            fieldKey: 'permitAdditionalInfo.isOptionPermitsAllowed',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
          },
          {
            fieldKey: 'permitAdditionalInfo.isCharterAirTransportLicenseRequired',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.isTemporaryImportationRequired',
            type: EDITOR_TYPES.SELECT_CONTROL,
            isBoolean: true,
            containerClass: classNames.containerClass,
          },
          {
            fieldKey: 'permitAdditionalInfo.temporaryImportationTiming',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: 'Hrs',
          },
          {
            fieldKey: 'permitAdditionalInfo.isLandingPermitRqrdForAircraftRegisteredCountry',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'permitAdditionalInfo.firstEntryNonAOE',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
            multiline: true,
            rows: 5,
          },
          {
            fieldKey: 'permitAdditionalInfo.temporaryImportation',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
            multiline: true,
            rows: 5,
          },
          {
            fieldKey: 'permitAdditionalInfo.isRevisionAllowed',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
    ];
  }

  private get headerActions(): ReactNode {
    return (
      <PermitEditorActions
        hasError={this.hasError || UIStore.pageLoading || this.isRowEditing}
        isDetailsView={this.isDetailsView}
        onCancelClick={() => this.onCancel()}
        onUpsert={() => this.onUpsertAction()}
        onSetViewMode={(mode: VIEW_MODE) => this.setViewMode(mode)}
      />
    );
  }

  public render(): ReactNode {
    const classes = this.props as IClasses;
    const { permitAdditionalInfo } = this.form.values();

    return (
      <ConfirmNavigate isBlocker={this.form.touched}>
        <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={!this.isDetailView}>
          <TitleContentWrapper permitTitle={this.permitModel.permitTitle} title="Additional Info">
            <div className={classes.flexWrap}>
              <ViewInputControlsGroup
                groupInputControls={this.inputControls}
                field={fieldKey => this.getField(fieldKey)}
                isEditing={this.isEditable}
                isLoading={this.loader.isLoading}
                onValueChange={(option: IOptionValue, fieldKey: string) => this.onValueChange(option, fieldKey)}
                onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
              />
            </div>
            <RevisionTriggerGrid
              isEditable={(this.isEditable || !this.isDetailView) && permitAdditionalInfo.isRevisionAllowed}
              permitAdditionalInfoRevisions={this.permitModel.permitAdditionalInfo.permitAdditionalInfoRevisions}
              onDataSave={revisions => this.updateRevisions(revisions)}
              onRowEditingChange={isEditing => this.updateRowEditing(isEditing)}
            />
          </TitleContentWrapper>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}

export default withRouter(withStyles(styles)(AdditionalInfo));
export { AdditionalInfo as PureAdditionalInfo };
