import React, { ReactNode, ReactElement } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import { VIEW_MODE } from '@wings/shared';
import { PermitModel } from '../../../Shared';
import { styles } from './PermitExceptionUpsert.styles';
import PermitUpsert, { BaseProps } from '../PermitUpsert/PermitUpsert';
import { PermitException, PermitEditorActions, PermitExceptionDetails } from '../../Components';
import { fields } from './Fields';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IClasses, IOptionValue, UIStore, withRouter } from '@wings-shared/core';
import { EDITOR_TYPES, ViewInputControl, ViewExpandInput, IViewInputControl } from '@wings-shared/form-controls';
import { ConfirmDialog, ConfirmNavigate, DetailsEditorWrapper, TitleContentWrapper } from '@wings-shared/layout';

type Props = BaseProps;

@inject('permitStore', 'permitSettingsStore', 'sidebarStore')
@observer
class PermitExceptionUpsert extends PermitUpsert<Props> {
  constructor(p: Props) {
    super(p, fields);
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.setInitialFormData(false);
  }

  @action
  private setInitialFormData(shouldSetDetail: boolean = true): void {
    const { permitDataModel } = this.permitStore;
    this.permitModel = new PermitModel({ ...permitDataModel });
    this.form.reset();
    this.setFormValues(permitDataModel);
    this.setExceptionTextDisabled(!permitDataModel.isException);
    this.setIsExceptionRuleAndValue();
    if (shouldSetDetail) {
      this.setViewMode(VIEW_MODE.DETAILS);
      this.setPermitDataChanged(false);
    }
  }

  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      if (this.isDataChanged) {
        return ModalStore.open(
          <ConfirmDialog
            title="Confirm Cancellation"
            message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
            yesButton="Yes"
            onNoClick={() => ModalStore.close()}
            onYesClick={() => {
              ModalStore.close();
              this.setInitialFormData();
            }}
          />
        );
      }
      this.setInitialFormData();
      return;
    }
    this.navigateToPermits();
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    if (fieldKey === 'isException') {
      this.setExceptionTextDisabled(!Boolean(value));
    }
    this.getField(fieldKey).set(value);
    this.updatePermitModel(this.permitModel);
  }

  @action
  private updatePermitModel(updatedPermitModel: PermitModel): void {
    const { permitDataModel } = this.permitStore;

    this.permitModel = new PermitModel({
      ...permitDataModel,
      ...this.form.values(),
      permitExceptionRules: [ ...updatedPermitModel?.permitExceptionRules ],
    });
    this.setPermitDataChanged(true);
    if (!updatedPermitModel.permitExceptionRules.length) {
      this.setIsExceptionRuleAndValue();
    }
  }

  private get hasExceptionsError(): boolean {
    return this.hasError || this.isExceptionDataInValid || this.hasPermitExceptionRuleError;
  }

  /* istanbul ignore next */
  private get exceptionTabInputControls(): IViewInputControl[] {
    return [
      {
        fieldKey: 'isException',
        type: EDITOR_TYPES.CHECKBOX,
        customErrorMessage: 'This field is required',
        isDisabled: this.permitModel.hasException,
        showTooltip: this.permitModel.hasException,
        tooltipText: this.permitModel.exceptionTooltipText,
      },
      {
        fieldKey: 'exception',
        type: EDITOR_TYPES.RICH_TEXT_EDITOR,
        isInputCustomLabel: true,
        multiline: true,
        rows: 10,
      },
    ];
  }

  private get exceptionTabViewInputControls(): ReactNode {
    return this.exceptionTabInputControls.map((inputControl: IViewInputControl, index: number) => (
      <ViewInputControl
        {...inputControl}
        key={index}
        customErrorMessage={inputControl.customErrorMessage}
        field={this.getField(inputControl.fieldKey || '')}
        isEditable={this.isEditable}
        onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
        onLabelClick={(label, fieldKey) => this.setExpandedMode(label, fieldKey, EDITOR_TYPES.RICH_TEXT_EDITOR)}
      />
    ));
  }

  private get exceptionAlert(): ReactElement {
    const classes = this.props.classes as IClasses;
    if (this.isEditable && this.permitModel.isException && Boolean(this.permitModel.exceptionAlertText)) {
      return (
        <Alert severity="error" className={classes.filledError}>
          {this.permitModel.exceptionAlertText}
        </Alert>
      );
    }
    return <></>;
  }

  private get headerActions(): ReactNode {
    return (
      <PermitEditorActions
        hasError={this.hasExceptionsError || UIStore.pageLoading}
        isDetailsView={this.isDetailsView}
        onCancelClick={() => this.onCancel()}
        onUpsert={() => this.onUpsertAction()}
        onSetViewMode={(mode: VIEW_MODE) => this.setViewMode(mode)}
      />
    );
  }

  public render(): ReactNode {
    return (
      <ConfirmNavigate isBlocker={this.isDataChanged}>
        <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={!this.isDetailView}>
          <TitleContentWrapper permitTitle={this.permitModel.permitTitle} title="Exception Information">
            <ViewExpandInput
              isExpandMode={this.expandMode}
              isEditable={this.isEditable}
              expandModeField={this.expandModeField}
              onGetField={(fieldKey: string) => this.getField(fieldKey)}
              onValueChange={(option, fieldKey) => this.onValueChange(option, fieldKey)}
              onLabelClick={(label, fieldKey) => this.setExpandedMode(label, fieldKey, EDITOR_TYPES.TEXT_FIELD)}
            />
            {!this.expandMode && (
              <>
                {this.exceptionAlert}
                {this.exceptionTabViewInputControls}
                {this.isDetailsView && !this.isEditable && <PermitExceptionDetails permitData={this.permitModel} />}
                {this.isEditable && (
                  <PermitException
                    permitModel={this.permitModel}
                    hasPermitExceptionRuleError={this.hasPermitExceptionRuleError}
                    onUpdatePermitModel={(permit: PermitModel) => this.updatePermitModel(permit)}
                  />
                )}
              </>
            )}
          </TitleContentWrapper>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}

export default withRouter(withStyles(styles)(PermitExceptionUpsert));
export { PermitExceptionUpsert as PurePermitExceptionUpsert };
