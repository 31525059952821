import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 40,
      marginRight: theme.spacing(1),
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 40,
    marginRight: theme.spacing(1),
  },
}));
