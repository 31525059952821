import React from 'react';
import { VIEW_MODE } from '@wings/shared';
import { INavigationLink } from '@wings-shared/layout';
import {
  SettingIcon,
  BriefCaseIcon,
  InfoIcon,
  DocumentIcon,
  ClockIcon,
  AdditionalInfoIcon,
  CheckCircleIcon,
  NoteIcon,
} from '@uvgo-shared/icons';
import PlayArrowIcon from '@material-ui/icons//PlayArrowOutlined';

/* istanbul ignore next */
export const sidebarOptions = (
  isInternalRoute: boolean,
  isDisabled: boolean = true,
  viewMode: VIEW_MODE = VIEW_MODE.NEW
): INavigationLink[] => (isInternalRoute ? permitInternalRoutes(isDisabled, viewMode) : permitExternalRoutes);

const permitExternalRoutes: INavigationLink[] = [
  {
    to: '',
    title: 'Permits',
    icon: <BriefCaseIcon />,
  },
  { to: 'executeRules', title: 'Execute Rules', icon: <PlayArrowIcon /> },
  { to: 'settings', title: 'Settings', icon: <SettingIcon /> },
];

/* istanbul ignore next */
const permitInternalRoutes = (isDisabled: boolean, viewMode: VIEW_MODE = VIEW_MODE.NEW): INavigationLink[] => [
  {
    to: '',
    title: 'General',
    icon: <InfoIcon />,
  },
  {
    to: 'exceptions',
    title: 'Exceptions',
    icon: <DocumentIcon />,
    isDisabled,
  },
  {
    to: 'lead-times',
    title: 'Lead Times',
    icon: <ClockIcon />,
    isDisabled,
  },
  {
    to: 'validity',
    title: 'Validity',
    icon: <CheckCircleIcon />,
    isDisabled,
  },
  {
    to: 'additional-info',
    title: 'Additional Info',
    icon: <AdditionalInfoIcon />,
    isDisabled,
  },
  {
    to: 'requirements',
    title: 'Requirements',
    icon: <DocumentIcon />,
    isDisabled,
  },
  {
    to: 'dm-note',
    title: 'DM Note',
    icon: <NoteIcon />,
    isDisabled,
  },
];
