import React, { ReactNode, MouseEvent as ReactMouseEvent } from 'react';
import classNames from 'classnames';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { IconButton, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { PrimaryButton } from '@uvgo-shared/buttons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IViewInputControl } from '@wings-shared/form-controls';
import {
  LogicalOperators,
  PermitExceptionRuleModel,
  PermitModel,
  PermitSettingsStore,
  PermitStore,
  PERMIT_RULE_SOURCES,
  RuleFilterModel,
} from '../../../Shared';
import { styles } from './PermitExceptionRule.styles';
import { PermitExceptionRuleViewControl, PermitRuleDeleteConfirmDialog } from '../../Components';
import { inputControls, exceptionInputControls } from './PermitExceptionRuleInputControls';
import { BasePermitException } from '../PermitException/BasePermitException';
import {
  IClasses,
  IOptionValue,
  ISelectOption,
  IdNameModel,
  UIStore,
  Utilities,
  SettingsTypeModel,
} from '@wings-shared/core';
import { Collapsable } from '@wings-shared/layout';
import { of, Observable } from 'rxjs';

interface Props {
  permitModel: PermitModel;
  classes?: IClasses;
  permitStore?: PermitStore;
  permitSettingsStore?: PermitSettingsStore;
  onUpdatePermitModel: (updatedPermitModel: PermitModel) => void;
}

@inject('permitStore', 'permitSettingsStore')
@observer
class PermitExceptionRule extends BasePermitException<Props> {
  @observable errors: IdNameModel<string>[] = [];

  private getExceptionRuleErrorKey(errorName: string, ruleFilterTempId: number, exceptionRuleIdTempId: number): string {
    return `${exceptionRuleIdTempId}_${ruleFilterTempId}_${errorName}`;
  }

  // Get API End Points based on the Selection
  private getAPISource(apiSource): Observable<any> {
    switch (apiSource) {
      case PERMIT_RULE_SOURCES.Country:
        return this.permitStore.getCountries();
      case PERMIT_RULE_SOURCES.FARType:
        return this.permitSettingsStore.getFARTypes();
      case PERMIT_RULE_SOURCES.PurposeOfFlight:
        return this.permitSettingsStore.getFlightPurposes();
      case PERMIT_RULE_SOURCES.NoiseChapter:
        return this.permitSettingsStore.getNoiseChapters();
      case PERMIT_RULE_SOURCES.Region:
        return this.permitStore.getRegions();
      case PERMIT_RULE_SOURCES.FIR:
        return this.permitStore.getFIRs();
      case PERMIT_RULE_SOURCES.AircraftCategory:
        return this.permitStore.getAircraftCategories();
      case PERMIT_RULE_SOURCES.ICAOAerodromeReferenceCode:
        return this.permitStore.getAerodromeReferenceCodes();
      case PERMIT_RULE_SOURCES.CrossingType:
        return this.permitSettingsStore.getCrossingType();
      case PERMIT_RULE_SOURCES.AirportOfEntry_AOE:
        return this.permitStore.getAirportOfEntries();
      default:
        return of([]);
    }
  }

  private onFocus(ruleFilter: RuleFilterModel | null): void {
    const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(ruleFilter as RuleFilterModel);
    this.getAPISource(entityParamConfig?.apiSource)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private onSearch(ruleFilter: RuleFilterModel | null, propertyValue: string): void {
    const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(ruleFilter as RuleFilterModel);
    switch (entityParamConfig?.apiSource) {
      case PERMIT_RULE_SOURCES.Airport:
        if (!propertyValue) {
          this.permitStore.wingsAirports = [];
          return;
        }
        UIStore.setPageLoader(true);
        this.permitStore
          .searchWingsAirports(propertyValue, true)
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => UIStore.setPageLoader(false))
          )
          .subscribe();
        break;
      case PERMIT_RULE_SOURCES.State:
        {
          UIStore.setPageLoader(true);
          const searchCollection = JSON.stringify(
            propertyValue
              ? [
                { propertyName: 'CommonName', operator: 'and', propertyValue },
                { propertyName: 'ISOCode', operator: 'or', propertyValue },
              ]
              : []
          );
          this.permitStore
            .getStates({ searchCollection, pageSize: 50 })
            .pipe(
              takeUntil(this.destroy$),
              finalize(() => UIStore.setPageLoader(false))
            )
            .subscribe();
        }
        break;
    }
  }

  private onAddRuleFilter(exceptionRule: PermitExceptionRuleModel): void {
    const updatedRuleFilters: RuleFilterModel[] = [
      ...exceptionRule.ruleFilters,
      new RuleFilterModel({ ruleLogicalOperator: LogicalOperators[0] }),
    ];
    this.updateExceptionRules(updatedRuleFilters, exceptionRule, true);
  }

  private onDeleteRule(exceptionRuleTempId: number): void {
    ModalStore.open(
      <PermitRuleDeleteConfirmDialog
        exceptionRuleTempId={exceptionRuleTempId}
        exceptionRules={this.exceptionRules}
        onUpdateExceptionRules={(exceptionRules: PermitExceptionRuleModel[]) => {
          this.errors = this.errors.filter((err: IdNameModel<string>) => !err.id.includes(`${exceptionRuleTempId}_`));
          this.updateExceptionRulesModel(exceptionRules);
        }}
      />
    );
  }

  @action
  private onBlur(
    value: IOptionValue | IOptionValue[],
    fieldKey: string,
    exceptionRule: PermitExceptionRuleModel,
    ruleFilter?: RuleFilterModel
  ): void {
    this.updateExceptionRuleErrors(value, fieldKey, ruleFilter?.tempId as number, exceptionRule?.tempId);
  }

  @action
  private onValueChange(
    value: IOptionValue | IOptionValue[],
    fieldKey: string,
    exceptionRule: PermitExceptionRuleModel,
    exceptionRuleFilters: RuleFilterModel[],
    ruleFilter: RuleFilterModel = {} as RuleFilterModel
  ): void {
    switch (fieldKey) {
      case 'permitRequirementType':
        exceptionRule.permitRequirementType = value as SettingsTypeModel;
        break;
      case 'name':
        exceptionRule.name = value as string;
        break;
      case 'ruleValues':
        const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(ruleFilter);
        ruleFilter.ruleValues = this.getExceptionRuleValues(
          entityParamConfig,
          value,
          ruleFilter.hasInOperator || ruleFilter?.hasNotInOperator
        );
        exceptionRuleFilters = this.getUpdatedRuleFilters(exceptionRuleFilters, ruleFilter);
        break;
      case 'ruleLogicalOperator':
        ruleFilter[fieldKey] = value as SettingsTypeModel;
        exceptionRuleFilters = this.getUpdatedRuleFilters(exceptionRuleFilters, ruleFilter);
        break;
      case 'ruleConditionalOperator':
        if (
          ruleFilter.ruleField?.name !== 'PermitOnFile' &&
          (!Boolean(value) || ruleFilter.hasInOperator || ruleFilter?.hasNotInOperator)
        ) {
          ruleFilter.ruleValues = null;
        }
        ruleFilter[fieldKey] = value as SettingsTypeModel;
        exceptionRuleFilters = this.getUpdatedRuleFilters(exceptionRuleFilters, ruleFilter);
        break;
      case 'ruleEntityType':
        ruleFilter.ruleEntityType = value as SettingsTypeModel;
        ruleFilter.ruleField = null;
        ruleFilter.ruleConditionalOperator = null;
        ruleFilter.ruleValues = null;
        exceptionRuleFilters = this.getUpdatedRuleFilters(exceptionRuleFilters, ruleFilter);
        break;
      case 'ruleField':
        ruleFilter.ruleField = value as SettingsTypeModel;
        ruleFilter.ruleConditionalOperator = null;
        ruleFilter.ruleValues = null;
        if (ruleFilter.ruleField?.name === 'PermitOnFile') {
          const entityParamConfig = this.permitSettingsStore.getSelectedEntityParamConfig(ruleFilter);
          ruleFilter.ruleValues = this.getExceptionRuleValues(
            entityParamConfig,
            'PTF',
            ruleFilter.hasInOperator || ruleFilter?.hasNotInOperator
          );
        }
        exceptionRuleFilters = this.getUpdatedRuleFilters(exceptionRuleFilters, ruleFilter);
        break;
      case 'delete':
        fieldKey = '';
        exceptionRuleFilters = exceptionRule.ruleFilters
          .filter((rule: RuleFilterModel) => rule.tempId !== ruleFilter.tempId)
          .map(
            (ruleData: RuleFilterModel, idx: number) =>
              new RuleFilterModel({
                ...ruleData,
                ruleLogicalOperator: idx === 0 ? null : new SettingsTypeModel({ ...ruleData.ruleLogicalOperator }),
              })
          );
        if (Boolean(exceptionRuleFilters.length)) {
          const errorKey: string = this.getExceptionRuleErrorKey(
            'ruleLogicalOperator',
            exceptionRuleFilters[0].tempId,
            exceptionRule.tempId
          );
          this.filterErrors(errorKey);
        }
        break;
    }

    this.updateExceptionRuleErrors(value, fieldKey, ruleFilter?.tempId, exceptionRule?.tempId);
    this.updateExceptionRules(exceptionRuleFilters, exceptionRule, true);
  }

  @action
  private updateExceptionRules(
    ruleFilters: RuleFilterModel[],
    exceptionRule: PermitExceptionRuleModel,
    isReplace: boolean
  ): void {
    const updatedExceptionRules: PermitExceptionRuleModel[] = Utilities.updateArray(
      this.exceptionRules,
      new PermitExceptionRuleModel({
        ...exceptionRule,
        ruleFilters: [ ...ruleFilters ],
      }),
      {
        replace: isReplace,
        predicate: t => t.tempId === exceptionRule.tempId,
      }
    );
    this.updateExceptionRulesModel(updatedExceptionRules);
  }

  @action
  private updateExceptionRuleErrors(
    value: IOptionValue | IOptionValue[],
    errorName: string,
    ruleFilterTempId: number,
    exceptionRuleId: number
  ): void {
    const errorKey: string = this.getExceptionRuleErrorKey(errorName, ruleFilterTempId, exceptionRuleId);

    if (!Boolean(errorName)) {
      this.errors = this.errors.filter((err: IdNameModel<string>) => !err.id.includes(errorKey));
      return;
    }

    this.setRuleValueBasedErrors(value, errorKey, errorName);
  }

  @action
  private setRuleValueBasedErrors(value: IOptionValue | IOptionValue[], errorKey: string, errorName: string): void {
    const errorsCopy: IdNameModel<string>[] = [ ...this.errors ];

    if (Array.isArray(value) && Boolean(value.length)) {
      this.filterErrors(errorKey);
      return;
    }

    if (typeof value === 'string' && Boolean(value?.trim())) {
      this.filterErrors(errorKey);
      return;
    }

    if (!Array.isArray(value) && typeof value === 'object' && Boolean(value)) {
      const selectedOption: ISelectOption = value as ISelectOption;

      if (Boolean(selectedOption.value)) {
        this.filterErrors(errorKey);
        return;
      }
    }

    this.errors = Utilities.updateArray(
      errorsCopy,
      { id: errorKey, name: errorName },
      {
        replace: true,
        predicate: t => t.id === errorKey,
      }
    );
  }

  @action
  private filterErrors(errorKey: string): void {
    this.errors = this.errors.filter((err: IdNameModel<string>) => err.id !== errorKey);
  }

  private get permitExceptionRuleRenderer(): ReactNode {
    const classes = this.props.classes as IClasses;
    return this.exceptionRules.map((exceptionRule: PermitExceptionRuleModel, idx: number) => (
      <Collapsable
        key={idx}
        titleVariant="h6"
        title={exceptionRule.name?.trim() || `Rule ${idx + 1}`}
        classes={{ titleRoot: classes.titleRoot, contentRoot: classes.contentRoot }}
        titleChildren={
          <IconButton
            classes={{ root: classes.delete }}
            onClick={(event: ReactMouseEvent<Element>) => {
              event.stopPropagation();
              this.onDeleteRule(exceptionRule.tempId);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        }
      >
        <>
          <div className={classes.rule}>
            {exceptionInputControls.map((inputControl: IViewInputControl, indx: number) => (
              <PermitExceptionRuleViewControl
                {...inputControl}
                key={indx}
                value={exceptionRule[inputControl.fieldKey || '']}
                exceptionRules={this.exceptionRules}
                exceptionRuleTempId={exceptionRule.tempId}
                errors={this.errors}
                customErrorMessage={inputControl.fieldKey === 'name' ? exceptionRule.hasInvalidName : ''}
                classes={{
                  inputControl: classNames({
                    [classes.exceptionRule]: inputControl.isHalfFlex,
                  }),
                }}
                onFocus={() => this.onFocus(null)}
                onSearch={(value: string) => this.onSearch(null, value)}
                onBlur={(fieldKey: string, value: IOptionValue) => this.onBlur(value, fieldKey, exceptionRule)}
                onValueChange={(value: IOptionValue, fieldKey: string) =>
                  this.onValueChange(value, fieldKey, exceptionRule, exceptionRule.ruleFilters)
                }
              />
            ))}
          </div>
          {!Boolean(exceptionRule.ruleFilters.length) && (
            <Alert severity="error" className={classes.filledError}>
              Please Add at least one exception case.
            </Alert>
          )}
          {exceptionRule.ruleFilters.map((ruleFilter: RuleFilterModel, index: number) => (
            <div className={classes.flexWrap} key={index}>
              {inputControls.map((inputControl: IViewInputControl) => (
                <PermitExceptionRuleViewControl
                  {...inputControl}
                  key={inputControl.fieldKey}
                  ruleFilter={ruleFilter}
                  errors={this.errors}
                  isFullFlex={index === 0 && inputControl.fieldKey === 'ruleValues'}
                  exceptionRuleTempId={exceptionRule.tempId}
                  isHidden={index === 0 && inputControl.fieldKey === 'ruleLogicalOperator'}
                  classes={{
                    inputControl: classNames({
                      [classes.exceptionRule]: index === 0 && inputControl.fieldKey === 'ruleValues',
                    }),
                  }}
                  isDisabled={inputControl.fieldKey === 'ruleValues' && ruleFilter.ruleField?.label === 'PermitOnFile'}
                  onFocus={() => this.onFocus(ruleFilter)}
                  onSearch={(value: string) => this.onSearch(ruleFilter, value)}
                  onBlur={(fieldKey: string, value: IOptionValue) =>
                    this.onBlur(value, fieldKey, exceptionRule, ruleFilter)
                  }
                  onValueChange={(value, fieldKey) =>
                    this.onValueChange(value, fieldKey, exceptionRule, exceptionRule.ruleFilters, ruleFilter)
                  }
                />
              ))}
            </div>
          ))}
          <div className={classes.action}>
            <PrimaryButton
              variant="contained"
              startIcon={<AddIcon />}
              disabled={!this.props.permitModel.isException}
              onClick={() => this.onAddRuleFilter(exceptionRule)}
            >
              Add New Case
            </PrimaryButton>
          </div>
        </>
      </Collapsable>
    ));
  }

  public render(): ReactNode {
    return this.permitExceptionRuleRenderer;
  }
}

export default withStyles(styles)(PermitExceptionRule);
